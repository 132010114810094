import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {ArticleComponent} from "../../@commons/@components/article/article.component";
import {TaskApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/task/task-api';
import {
  TaskListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/task-list.request';
import {StorageService} from '../../@commons/@services/front/storage.service';
import {
  TaskListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/task-list.response';

@Component({
  selector: 'vw-agenda-archives', // Component selector
  standalone: true, // Indicates this is a standalone component
    imports: [CommonModule, SharedModule, TranslateModule, ArticleComponent], // Modules required by this component
  templateUrl: './agenda-archives.component.html', // HTML template for the component
  styleUrl: './agenda-archives.component.scss', // Stylesheet for the component
  providers: [{
    provide: TaskApi,
    useClass: TaskApi
  }]
})
export class AgendaArchivesComponent implements OnInit {

  /**
   * List of archived tasks.
   * @type {TaskListResponse[]}
   */
  tasks: TaskListResponse[] = [];
  currentTaskPage: number = 0;
  taskPageSize: number = 20;

  /**
   * List of advices related to the agenda.
   * @type {any[]}
   */
  advices: any[] = [];

  /**
   * Initializes the AgendaArchivesComponent with required services.
   * @param {TaskApi} taskApi - Service to fetch agenda-related data
   * @param storageService
   */
  constructor(private taskApi: TaskApi,
              private storageService: StorageService) {}

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Subscribes to tasks and advices data from AgendaService.
   */
  async ngOnInit() {
    const taskFilters: TaskListRequest = new TaskListRequest();
    this.currentTaskPage = 0;
    await this.loadTasks();
    await this.loadArticles();
  }

  async loadTasks() {
    const taskFilters: TaskListRequest = new TaskListRequest();
    const response = await this.taskApi.list(taskFilters, this.currentTaskPage, this.taskPageSize, this.storageService.getCurrentLanguage());
    this.tasks.push(...response.results);
  }

  async loadMoreTasks() {
    this.currentTaskPage++;
    await this.loadTasks();
  }

  async loadArticles() {
    /*this.agendaService.getAgendaAdvice().subscribe((advices) => {
      this.advices = advices;
    });*/
  }
}
