<div class="grid grid-cols-4 gap-4 mx-4 items-center ">
  <vw-app-menu-item [label]="'common.modals.ai-actions.content.actions.scan'" (click)="scanClicked()">
    <vw-scan-barcode-icon class="icon" iconTemplate></vw-scan-barcode-icon>
  </vw-app-menu-item>
  <vw-app-menu-item [label]="'common.modals.ai-actions.content.actions.add'" (click)="addClicked()">
    <vw-circle-plus-icon class="icon text-primary" iconTemplate></vw-circle-plus-icon>
  </vw-app-menu-item>
  <vw-app-menu-item [label]="'common.modals.ai-actions.content.actions.tasks'" (click)="showTasksClicked()">
    <vw-task-icon iconTemplate></vw-task-icon>
  </vw-app-menu-item>
  <vw-app-menu-item [label]="'common.modals.ai-actions.content.actions.map'" (click)="showMapClicked()"
    [disabled]="true">
    <vw-earth-icon class="icon" iconTemplate></vw-earth-icon>
  </vw-app-menu-item>
</div>