import { Component, Input, OnChanges } from '@angular/core';
import { SharedModule } from '../../../../@commons/ui-components/shared.module';
import { MyCultivationListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-list.response';
import { NgClass, NgIf } from '@angular/common';
import { HexToHsl } from '../../../../@commons/pipes/HexToHsl';
import { CalendarCultivationListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';

@Component({
  selector: 'vw-cultivation-item-viewer',
  standalone: true,
  imports: [SharedModule, NgClass, NgIf, HexToHsl],
  templateUrl: './cultivation-item-viewer.component.html',
  styleUrl: './cultivation-item-viewer.component.scss',
})
export class CultivationItemViewerComponent implements OnChanges {
  @Input() cultivation!:
    | MyCultivationListResponse
    | CalendarCultivationListResponse;
  @Input() displayType: 'BIG' | 'SMALL' | 'TINY' = 'BIG';
  @Input() showName: boolean = false;
  imageUrl: string =
    'https://1c9c8h-hv.myshopify.com/cdn/shop/files/product-img-1.png?v=1738043832';

  ngOnChanges() {
    //TODO - Implement this method
    if (this.cultivation && this.cultivation.files?.length > 0) {
      this.imageUrl =
        this.cultivation.files[this.cultivation.files.length - 1].cdnUrl;
    }
  }
}
