import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AiChatbotMessageComponent } from './ai-chatbot-message/ai-chatbot-message.component';
import { NgForOf, NgClass } from '@angular/common';

@Component({
  selector: 'vw-ai-chatbot-discussion',
  standalone: true,
  imports: [AiChatbotMessageComponent, NgForOf, NgClass],
  templateUrl: './ai-chatbot-discussion.component.html',
  styleUrl: './ai-chatbot-discussion.component.scss',
})
export class AiChatbotDiscussionComponent {
  @Input() messages: Message[] = [];
  @Input() botLoading: boolean = false;
  @Output() messageSubmitted = new EventEmitter<string>();

  isLastGroupMessage(index: number): boolean {
    if (index === this.messages.length - 1) {
      return true;
    }
    return this.messages[index].isUser !== this.messages[index + 1].isUser;
  }

  onMessageSubmitted(message: string) {
    this.messageSubmitted.emit(message);
  }
}

export interface Message {
  message: string;
  isUser: boolean;
  dateTime: number;
}
