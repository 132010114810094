import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SharedModule } from '../@commons/ui-components/shared.module';
import { TaskComponent } from '../@commons/@components/task/task.component';
import { ArticleComponent } from '../@commons/@components/article/article.component';
import { HeaderHomeComponent } from './@components/header-home/header-home.component';
import { fadeIn } from '../@commons/animations/animations';
import { Router } from '@angular/router';
import routesConstants from '../@commons/constants/routes.constants';
import { WeatherCardComponent } from './@components/weather-card/weather-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TaskApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/task/task-api';
import { TaskListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/task-list.response';
import { StorageService } from '../@commons/@services/front/storage.service';
import { UserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';
import { User } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/user.entity';
import { CultivationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-api';
import { WeatherApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/weather/weather-api';
import { MoonInfoCardComponent } from './@components/moon-info-card/moon-info-card.component';
import { AddressToUserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/address-to-user/address-to-user-api';
import { UserResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/user/user.response';
import { AddressToUserListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/address-to-user-list.response';
import { PagingResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/pagination/paging.response';
import { HeaderPaddingSafeAreaComponent } from '../@commons/@components/header-padding-safe-area/header-padding-safe-area.component';
import { CultivationHierarchyApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import { MyCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-hierarchy-list.response';
import { UserHierarchiesComponent } from '../garden/user-hierarchies/user-hierarchies.component';
import { CiclePlusIconComponent } from '../@icons/circle-plus-icon.component';
import { SkeletonLoaderComponent } from '../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import {ArticleApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/article/article-api';
import {
  ArticleListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/article/article-list.request';

@Component({
  selector: 'vw-home', // Selector for the component to be used in templates
  standalone: true, // Indicates that this component is standalone
  imports: [
    SharedModule,
    CommonModule,
    WeatherCardComponent,
    TaskComponent,
    ArticleComponent,
    HeaderHomeComponent,
    TranslateModule,
    MoonInfoCardComponent,
    HeaderPaddingSafeAreaComponent,
    UserHierarchiesComponent,
    CiclePlusIconComponent,
    SkeletonLoaderComponent,
  ], // Imports shared and common modules
  templateUrl: './home.component.html', // Path to the component's template
  styleUrls: ['./home.component.scss'], // Path to the component's styles
  animations: [fadeIn],
  providers: [
    {
      provide: ArticleApi,
      useClass: ArticleApi,
    },
    {
      provide: TaskApi,
      useClass: TaskApi,
    },
    {
      provide: UserApi,
      useClass: UserApi,
    },
    {
      provide: CultivationApi,
      useClass: CultivationApi,
    },
    {
      provide: CultivationHierarchyApi,
      useClass: CultivationHierarchyApi,
    },
    {
      provide: WeatherApi,
      useClass: WeatherApi,
    },
    {
      provide: AddressToUserApi,
      useClass: AddressToUserApi,
    },
  ],
})
export class HomeComponent implements OnInit {
  tasks: TaskListResponse[] = []; // Array to hold tasks
  isModalOpen: boolean = false; // State variable to manage modal visibility
  hierarchies: MyCultivationHierarchyListResponse[] = []; // Array to hold the list of plants
  articles: any[] = []; // Array to hold badge information
  user: User;
  userAddressUid: string;
  userLoaded: boolean = false;
  isLoading: boolean = true;

  // Example data for the cards
  /*cards = [
    {
      label: '19°c',
      imageUrl: '/assets/svg/cloud-rain-colored.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#E1EDFE]',
      timeLabelStyle: 'text-[#0F453B]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#1A4166]',
    },
    {
      label: '19°c',
      imageUrl: '/assets/svg/cloud-rain-white.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#205280]',
      timeLabelStyle: 'text-[#FFFFFFB2]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#FFFFFF]',
    },
    {
      label: '24°c',
      imageUrl: '/assets/svg/sun-white.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#F4D537]',
      timeLabelStyle: 'text-[#FFFFFFB2]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#FFFFFF]',
    },
    {
      label: '24°c',
      imageUrl: '/assets/svg/sun.svg',
      title: 'Pas besoin d’arroser aujourd’hui',
      tailwindClasses: 'bg-[#F49F37]',
      timeLabelStyle: 'text-[#0F453B]',
      expanded: false,
      weatherImg: '/assets/svg/weather-tree.svg',
      WeatherForecastLabel: 'text-[#1A4166]',
    },
    {
      label: 'Dernier Quartier ',
      imageUrl: '/assets/svg/moon.svg',
      title: 'Ne pas jardiner',
      tailwindClasses: 'bg-[#382E96]',
      timeLabelStyle: 'text-[#DAF2E0]',
      expanded: false,
      weatherImg: '/assets/svg/moon-tree.svg',
      WeatherForecastLabel: 'text-[#FFFFFF]',
    },
  ];*/

  constructor(
    private router: Router,
    private taskApi: TaskApi,
    private userApi: UserApi,
    private addressToUserApi: AddressToUserApi,
    private cultivationApi: CultivationApi,
    private cultivationHierarchyApi: CultivationHierarchyApi,
    private storageService: StorageService,
    private translate: TranslateService,
    private weatherApi: WeatherApi,
    private articleApi: ArticleApi,
  ) { } // Constructor to inject the TaskService

  async ngOnInit() {
    // Fetch tasks, badge information, and task items on component initialization
    this.loadTasks();
    await this.loadUser();
    await this.loadUserCultivations();
    this.isLoading = false;
    const listArticle: ArticleListRequest = new ArticleListRequest();
    await this.articleApi.list(listArticle);
  }

  loadTasks() {
    const taskFilters: TaskListResponse = new TaskListResponse();
    this.taskApi
      .list(taskFilters, 0, 10, this.storageService.getCurrentLanguage())
      .then((TaskPaging) => {
        if (TaskPaging) {
          this.tasks.push(...TaskPaging.results);
        }
      });
  }

  async loadUser() {
    const userResponse: UserResponse = await this.userApi.getCurrentUser();
    this.user = userResponse;
    const addressToUserPaging: PagingResponse<AddressToUserListResponse> =
      await this.addressToUserApi.list(
        {
          userUid: userResponse.uid,
        },
        0,
        10,
        this.storageService.getCurrentLanguage()
      );
    if (addressToUserPaging.results.length > 0) {
      this.userAddressUid = addressToUserPaging.results[0].addressUid;
      this.userApi.addUserAddressUid(this.userAddressUid);
    }

    if (this.user && this.user.preferredLanguage) {
      this.storageService.setCurrentLang(
        this.user.preferredLanguage.toUpperCase()
      );
      this.translate.use(this.user.preferredLanguage.toLowerCase());
    }
    this.userLoaded = true;
  }

  async loadUserCultivations() {
    this.hierarchies = await this.cultivationHierarchyApi.myGarden(
      this.storageService.getCurrentLanguage()
    );
  }

  /**
   * Emits a profile click event to the parent component.
   */
  onProfileClick() {
    this.router.navigate([routesConstants.PROFILE]);
  }

  /**
   * Opens the modal by setting isModalOpen to true.
   */
  openModal() {
    this.isModalOpen = true; // Open the modal
  }

  /**
   * Closes the modal by setting isModalOpen to false.
   */
  closeModal() {
    this.isModalOpen = false; // Close the modal
  }

  /**
   * Logs a message when the modal is closed and invokes closeModal.
   */
  onModalClose() {
    this.closeModal(); // Close the modal
  }

  /**
   * Logs a confirmation message and closes the modal.
   */
  confirmAction() {
    this.closeModal(); // Close the modal
  }

  // Card click event handler
  onWeatherCardClicked() { }

  // Card click event handler
  onMoonCardClicked() { }

  onAddPlant() {
    this.router.navigate([routesConstants.GARDEN_ADDPLANT]);
  }
}
