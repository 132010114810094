<vw-toast-popup [isOpen]="isOpen" height="90%" width="100%" borderRadius="20px" customClass=" bg-white">
  <div class="w-full h-full flex flex-col relative">
    <div class="flex items-center mt-2 mx-4 pb-2 border-b-[0.6px] border-[#D7D8DC] justify-between">
      <vw-icon-svg iconUrl="/assets/images/vegiwise/vegiwise-app-logo.png" additionalClasses="product-img" width="50"
        height="50"></vw-icon-svg>
      <div class="mt-1"><vw-card-block-title label="common.modals.ai-actions.header.title"></vw-card-block-title></div>
      <div class="flex items-center justify-end" (click)="onClose()">
        <vw-icon-svg iconUrl="/assets/svg/close2.svg" additionalClasses="cursor-pointer" width="26px"
          height="26px"></vw-icon-svg>
      </div>
    </div>
    <div class="overflow-y-auto flex-grow">
      <vw-ai-chatbot (inputFocus)="onInputFocus()" (inputBlur)="onInputBlur()"></vw-ai-chatbot>
    </div>
    <div class="mb-28 w-full" [ngClass]="{'hidden': inputIsFocus}">
      <vw-modal-ai-actions-buttons (addPlant)="onAddPlant()" (showTasks)="onShowTasks()" (showMap)="onShowMap()"
        (scan)="onScanBarcode()">
      </vw-modal-ai-actions-buttons>
    </div>
  </div>
</vw-toast-popup>