<div>
  <div *ngFor="let task of tasks">
    <vw-task [task]="task"></vw-task>
  </div>

  <div class="pb-6 pt-6">
    <div class="mt-0">
      <h1 class="font-semibold text-2xl text-primary-title">{{'agenda.tabs.current.advices' | translate}}</h1>
    </div>
    <vw-article *ngFor="let advice of advices" [article]="advice"></vw-article>
  </div>
</div>
