import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { fadeIn } from '../@commons/animations/animations';
import { SharedModule } from '../@commons/ui-components/shared.module';
import {
  HeaderPaddingSafeAreaComponent
} from '../@commons/@components/header-padding-safe-area/header-padding-safe-area.component';
import RoutesConstants from '../@commons/constants/routes.constants';
import {
  CultivationHierarchyApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import { SkeletonLoaderComponent } from '../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import { PlusIconComponent } from '../@icons/plus-icon.component';
import { CiclePlusIconComponent } from '../@icons/circle-plus-icon.component';



@Component({
  selector: 'vw-garden', // Selector for the potager component
  standalone: true, // Indicates that this component is standalone
  imports: [CommonModule, SharedModule, RouterOutlet, TranslateModule, HeaderPaddingSafeAreaComponent, SkeletonLoaderComponent, PlusIconComponent, CiclePlusIconComponent], // Required modules and components
  templateUrl: './garden.component.html', // Path to the component's HTML template
  styleUrls: ['./garden.component.scss'], // Path to the component's SCSS styles
  providers: [{
    provide: CultivationHierarchyApi, // Provide the CultivationHierarchyApi service
    useClass: CultivationHierarchyApi // Use the CultivationHierarchyApi service
  }],
  animations: [fadeIn]
})
export class GardenComponent {
  activeTab = 0; // Default tab index (0 for the first tab)
  cultivationCounts = 0; // Default cultivation count
  isLoading = true;
  showAddDropdown = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private hierarchyApi: CultivationHierarchyApi) { } // Constructor to inject the TaskService

  /**
   * Array containing data for the tabs.
   * Each object represents a tab with its label, icon, and styling classes.
   * @type {Array<{ label: string; icon: string; tailwindClasses: string; width: string; border: string; }>}
   */
  tabData = [];

  setTabData() {
    this.tabData = [
      {
        label: 'garden.tabs.cultivations.tab_title', labelPayload: { cultivationsCount: this.cultivationCounts },
        icon: 'fas fa-home', tailwindClasses: 'text-sm', width: '100%', border: 'rounded-full'
      },
      { label: 'garden.tabs.seeds.tab_title', icon: 'fas fa-search', tailwindClasses: 'text-sm', width: '100%', border: 'rounded-full' },
    ];
  }



  ngOnInit() {
    this.hierarchyApi.myGarden('en').then((response) => {
      this.cultivationCounts = response.length;
      this.setTabData();
      this.isLoading = false;
    });
    this.route.firstChild?.url.subscribe((urlSegments) => {
      const routePath = urlSegments[0]?.path;
      if (routePath === 'list') {
        this.activeTab = 0;
      } else if (routePath === 'seeds') {
        this.activeTab = 1;
      }
    });
  }

  /**
 * Method to handle tab selection.
 * Updates the activeTab index based on the selected tab index.
 * @param {number} index - The index of the selected tab.
 */
  onTabSelected(index: number) {
    this.activeTab = index; // Set the active tab to the selected index
    // Navigate to the appropriate route based on the selected tab index
    if (index === 0) {
      this.router.navigate([RoutesConstants.GARDEN_LIST]);
    } else if (index === 1) {
      this.router.navigate([RoutesConstants.GARDEN_SEEDS]);
    }
  }

  onAddPlant() {
    this.router.navigate([RoutesConstants.GARDEN_ADDPLANT]);
  }

  onAddSeed() {
    this.router.navigate([RoutesConstants.GARDEN_SEEDS]);
  }
}
