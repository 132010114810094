import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SharedModule } from '../../@commons/ui-components/shared.module';
import {
  CultivationHierarchyApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import { CultivationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-api';
import { CommonModule } from '@angular/common';
import { UserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';
import {
  AddressToUserApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/address-to-user/address-to-user-api';
import {
  MyCultivationHierarchyListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-hierarchy-list.response';
import { User } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/user.entity';
import { Router } from '@angular/router';
import { StorageService } from '../../@commons/@services/front/storage.service';
import {
  CultivationHierarchy
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-hierarchy.entity';
import {
  Cultivation
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';
import { CultivationItemComponent } from '../@components/cultivation-item/cultivation-item.component';
import { HierarchyItemComponent } from '../@components/hierarchy-item/hierarchy-item.component';
import {
  HierarchyItemsChoiceModalComponent
} from '../@modals/hierarchy-items-choice-modal/hierarchy-items-choice-modal.component';
import { SkeletonLoaderComponent } from '../../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import RoutesConstants from '../../@commons/constants/routes.constants';
import { PlusIconComponent } from '../../@icons/plus-icon.component';

@Component({
  selector: 'vw-user-hierarchies', // Selector for the component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, CultivationItemComponent, HierarchyItemComponent, HierarchyItemsChoiceModalComponent, SkeletonLoaderComponent, PlusIconComponent], // Required modules for the component
  templateUrl: './user-hierarchies.component.html', // Path to the component's HTML template
  styleUrls: ['./user-hierarchies.component.scss'], // Path to the component's SCSS styles
  providers: [{
    provide: CultivationApi,
    useClass: CultivationApi
  }, {
    provide: CultivationHierarchyApi,
    useClass: CultivationHierarchyApi
  },
  {
    provide: UserApi,
    useClass: UserApi
  }, {
    provide: AddressToUserApi,
    useClass: AddressToUserApi
  }]
})
export class UserHierarchiesComponent implements OnInit, OnChanges {
  @Input() hierarchies: MyCultivationHierarchyListResponse[] = []
  @Input() isLoading: boolean = true;
  isModalOpen: boolean = false; // Flag to control modal visibility
  userHierarchies: MyCultivationHierarchyListResponse[] = []; // Array to hold the list of cultivations
  selectedHierarchy: MyCultivationHierarchyListResponse; // Currently selected cultivation for modal actions
  user: User;
  userAddressUid: string;
  constructor(private router: Router,
    private storageService: StorageService,
    private cultivationHierarchyApi: CultivationHierarchyApi,
    private addressToUserApi: AddressToUserApi,
    private userApi: UserApi) { } // Injecting the router and potager service

  /**
   * Lifecycle hook that is called after the component is initialized.
   * Fetches the list of cultivations from the service.
   */
  async ngOnInit() {

    if (this.router.url === '/garden/list') {
      await this.loadUser();
      await this.loadHierarchies();
      this.isLoading = false;
    } else {
      this.userHierarchies = this.hierarchies
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hierarchies'] && !changes['hierarchies'].firstChange) {
      this.userHierarchies = this.hierarchies
    }
  }

  async loadUser() {
    this.user = await this.userApi.getCurrentUser();
  }

  async loadHierarchies() {
    this.userHierarchies = await this.cultivationHierarchyApi.myGarden(this.storageService.getCurrentLanguage());
  }

  /**
   * Opens the modal and sets the selected hierarchy.
   * @param hierarchy - The hierarchy to display in the modal
   */
  openModal(hierarchy: MyCultivationHierarchyListResponse) {
    // Check if varietyType is not 0 and not undefined
    if (hierarchy.cultivations && hierarchy.cultivations.length > 1) {
      this.selectedHierarchy = hierarchy;  // Set the selected cultivation
      this.isModalOpen = true;      // Show the modal
    } else {
      const cultivation = hierarchy.cultivations[0];
      const targetUrl = `/garden/cultivations/${cultivation.uid}/plants`;
      this.router.navigate([targetUrl], {
        state: {
          cultivationId: hierarchy.uid,
          varietyType: null,
          name: hierarchy.name
        }
      });
    }
  }

  /**
   * Closes the modal and resets the selected hierarchy.
   */
  closeModal() {
    this.isModalOpen = false; // Reset selected cultivation on close
    console.log('Modal has been closed');
  }

  /**
   * Handles the modal close action, calls closeModal.
   */
  onModalClosed() {
    this.closeModal();
  }

  /**
   * Confirms the action taken in the modal and closes it.
   */
  confirmAction() {
    this.closeModal();
  }

  /**
   * Navigates to the variety cultivation route when a cultivation is selected.
   */
  onCultivationSelected(hierarchy: CultivationHierarchy, cultivation: Cultivation) {
    // Navigate to the updated path structure, including the cultivationname
    const targetUrl = `/garden/cultivations/${cultivation.uid}/plants`;
    this.closeModal();
    this.router.navigate([targetUrl], {
      state: {
        hierarchyUid: hierarchy!.uid,  // Explicitly pass varietyType as well
        cultivationUid: cultivation!.uid,  // Pass the variant ID
        name: cultivation!.name,  // Pass the cultivation name
      }
    });
  }

  onAddPlant() {
    this.router.navigate([RoutesConstants.GARDEN_ADDPLANT]);
  }
}
