<div class="flex flex-col gap-1 my-3">
  <ng-container *ngFor="let message of messages; let i = index">
    <div
      class="flex gap-2"
      [ngClass]="message.isUser ? 'flex-row-reverse' : 'flex-row'"
    >
      @if(i === 0) {
      <div class="size-9 rounded-full overflow-hidden">
        <!-- TODO: set correct image -->
        <img
          src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
          alt="willy"
          class="object-cover w-full h-full"
        />
      </div>
      }
      <vw-ai-chatbot-message
        [text]="message.message"
        [align]="message.isUser ? 'right' : 'left'"
        [color]="message.isUser ? '#30A156' : '#F5F5F5'"
        [textColor]="message.isUser ? '#FFFFFF' : '#28292D'"
        [date]="message.dateTime"
        [showDate]="isLastGroupMessage(i)"
      />
      <vw-ai-chatbot-message
        *ngIf="botLoading"
        [text]="'...'"
        [align]="'left'"
        [color]="'#F5F5F5'"
        [textColor]="'#28292D'"
        [showDate]="false"
      />
    </div>
  </ng-container>
</div>
