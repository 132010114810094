import { Component } from '@angular/core';

@Component({
  selector: 'vw-chevron-right-icon',
  standalone: true,
  imports: [],
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6"/></svg>`,
})
export class ChevronRightIconComponent {

}
