<div class="flex gap-y-4 flex-wrap -mx-2 h-full  mb-28" *ngIf="!isLoading">
  <div *ngFor="let hierarchy of userHierarchies" class="w-2/4 h-full" (click)="openModal(hierarchy)">
    <vw-hierarchy-item [hierarchy]="hierarchy"></vw-hierarchy-item>
  </div>

</div>
<div *ngIf="isLoading" class="grid grid-cols-2 gap-8">
  <vw-skeleton-loader *ngFor="let i of [1,2]" [width]="'100%'" [height]="'200px'"
    [borderRadius]="'0.75rem'"></vw-skeleton-loader>
</div>

<vw-hierarchy-items-choice-modal [isOpen]="isModalOpen" [hierarchy]="selectedHierarchy"
  (onCultivationSelected)="onCultivationSelected(selectedHierarchy, $event)" (onModalClosed)="onModalClosed()">
</vw-hierarchy-items-choice-modal>