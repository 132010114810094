import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'base-modal',
  template: ``,
})

export abstract class BaseModalComponent  {
  @Input() isOpen: boolean;
  @Output() onModalClosed = new EventEmitter<void>();

  close() {
    this.isOpen = false;
    this.onModalClosed.emit();
  }
}
