import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../../@commons/ui-components/shared.module';
import { BaseToastComponent } from '../../../../../@commons/ui-components/toast-popup/base-toast-component';
import { Address } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/address.entity';
import { CloseBtnComponent } from '../../../../../@commons/@components/close-btn/close-btn.component';
import { LocationInputComponent } from '../../../../../@commons/ui-components/location-input/location-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddressApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/address/address-api';
import { ApiException } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/@common/exception/base.exception';
import { UpdateAddressRequest } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/update-address.request';
import { UserAdressLocationCardComponent } from './user-adress-location-card/user-adress-location-card.component';
import { UserAdressLocationFormComponent } from './user-adress-location-form/user-adress-location-form.component';
import { LocationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/location-api';
import { LocationListRequest } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/location-list.request';
import { StorageService } from '../../../../../@commons/@services/front/storage.service';
import { CommonModule } from '@angular/common';
import { CheckIconComponent } from '../../../../../@icons/check-icon.component';
import { PlusIconComponent } from '../../../../../@icons/plus-icon.component';

@Component({
  selector: 'vw-edit-user-adress-toast',
  standalone: true,
  imports: [CloseBtnComponent, SharedModule, CommonModule, UserAdressLocationFormComponent, LocationInputComponent, TranslateModule, UserAdressLocationCardComponent, CheckIconComponent, PlusIconComponent],
  templateUrl: './edit-user-adress-toast.component.html',
  styleUrl: './edit-user-adress-toast.component.scss',
  providers: [{
    provide: AddressApi,
    useClass: AddressApi,
  },
  {
    provide: LocationApi,
    useClass: LocationApi,
  }
  ]
})
export class EditUserAdressToastComponent extends BaseToastComponent {
  @Input() address: Address;

  addressInput: string = '';
  selectedFeature: any;
  isUpdatingAddress = false;

  locations: any = [];
  currentLocation?: any;
  isLocationFormOpen = false;

  async ngOnInit() {
    this.addressInput = `${this.address.line1 ? this.address.line1 + ' ' : ''}${this.address.line2 ? this.address.line2 + ' ' : ''}${this.address.postalCode ? this.address.postalCode + ' ' : ''}${this.address.city ?? ''}`;
    const response = await this.locationApi.list({ addressUid: this.address.uid }, 0, 50, this.storageService.getCurrentLanguage());
    this.locations = response.results;
  }

  constructor(private addressApi: AddressApi, private locationApi: LocationApi, private storageService: StorageService) {
    super();
  }

  async onSelectFeature(feature: any) {
    this.selectedFeature = feature;
    this.addressInput = feature.place_name;

  }

  getValueFromFeature(
    feature: any,
    key: string,
    targetVal: string = 'text'
  ): string {
    let result = undefined;

    if (feature.id && feature.id.indexOf(key) >= 0) {
      result = feature[targetVal];
    } else if (
      feature.context.some((context: any) => context.id.indexOf(key) >= 0)
    ) {
      result = feature.context.find(
        (context: any) => context.id.indexOf(key) >= 0
      )[targetVal];
    }

    return result;
  }

  async onSaveAddress() {
    this.isUpdatingAddress = true;
    const updateAddressRequest: UpdateAddressRequest = new UpdateAddressRequest();
    updateAddressRequest.uid = this.address.uid;
    if (this.selectedFeature.context) {
      updateAddressRequest.city = this.getValueFromFeature(
        this.selectedFeature,
        'municipality'
      );
      updateAddressRequest.postalCode = this.getValueFromFeature(
        this.selectedFeature,
        'postal_code'
      );
      updateAddressRequest.countryCode = this.getValueFromFeature(
        this.selectedFeature,
        'country',
        'country_code'
      );
      updateAddressRequest.country = this.getValueFromFeature(
        this.selectedFeature,
        'country'
      );
      updateAddressRequest.line1 = this.selectedFeature.place_name;
      updateAddressRequest.fullAddress = this.selectedFeature.place_name;

      if (this.selectedFeature.center) {
        updateAddressRequest.latitude = this.selectedFeature.center[1];
        updateAddressRequest.longitude = this.selectedFeature.center[0];
      }

      updateAddressRequest.address = this.selectedFeature.place_name;
      await this.addressApi.update(updateAddressRequest);
    }
    this.isUpdatingAddress = false;
    this.onClose();
  }

  override onClose() {
    super.onClose();
    this.isLocationFormOpen = false;
  }

  onEditLocation(locationUid: string) {
    this.currentLocation = this.locations.find((location: any) => location.uid === locationUid);
    this.isLocationFormOpen = true;
  }

  onCreateLocation() {
    this.currentLocation = undefined;
    this.isLocationFormOpen = true;
  }

  async onCloseLocationForm() {
    const response = await this.locationApi.list({ addressUid: this.address.uid }, 0, 50, this.storageService.getCurrentLanguage());
    this.locations = response.results;
    this.isLocationFormOpen = false;
  }
}
