import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {
  CultivationHierarchyApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import { CultivationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-api';
import { CommonModule } from '@angular/common';
import { UserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';
import {
  AddressToUserApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/address-to-user/address-to-user-api';
import {
  MyCultivationHierarchyListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-hierarchy-list.response';
import { User } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/user.entity';
import { Router } from '@angular/router';
import {
  CultivationHierarchy
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-hierarchy.entity';
import {
  Cultivation
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';
import {
  CalendarCultivationHierarchyListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';
import { SkeletonLoaderComponent } from '../../../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import {
  HierarchyItemsChoiceModalComponent
} from '../../../garden/@modals/hierarchy-items-choice-modal/hierarchy-items-choice-modal.component';
import { HierarchyItemComponent } from '../../../garden/@components/hierarchy-item/hierarchy-item.component';
import { CultivationItemComponent } from '../../../garden/@components/cultivation-item/cultivation-item.component';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { StorageService } from '../../../@commons/@services/front/storage.service';
import {
  CultivationGuideToastComponent
} from '../../../garden/@toasts/cultivation-guide-toast/cultivation-guide-toast.component';
import {
  CultivationVariant
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import {
  CalendarCultivationListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';
import { CultivationItemViewerComponent } from '../../../garden/@components/hierarchy-item/cultivation-item-viewer/cultivation-item-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { HexToHsl } from '../../../@commons/pipes/HexToHsl';
import { ChevronRightIconComponent } from '../../../@icons/chevron-right.component';
import { CalendarHierarchyItemComponent } from '../calendar-hierarchy-item/calendar-hierarchy-item.component';

@Component({
  selector: 'vw-calendar-hierarchies', // Selector for the component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, CultivationItemComponent, HierarchyItemComponent, HierarchyItemsChoiceModalComponent, SkeletonLoaderComponent, CultivationGuideToastComponent, CultivationItemViewerComponent, TranslateModule, HexToHsl, ChevronRightIconComponent, CalendarHierarchyItemComponent], // Required modules for the component
  templateUrl: './calendar-hierarchies.component.html', // Path to the component's HTML template
  styleUrls: ['./calendar-hierarchies.component.scss'], // Path to the component's SCSS styles
  providers: [{
    provide: CultivationApi,
    useClass: CultivationApi
  }, {
    provide: CultivationHierarchyApi,
    useClass: CultivationHierarchyApi
  },
  {
    provide: UserApi,
    useClass: UserApi
  }, {
    provide: AddressToUserApi,
    useClass: AddressToUserApi
  }]
})
export class CalendarHierarchiesComponent implements OnInit {
  @Input() hierarchies: CalendarCultivationHierarchyListResponse[] = []
  @Input() isLoading: boolean = true;
  @Output() cultivationSelected: EventEmitter<CalendarCultivationListResponse> = new EventEmitter();
  isShowingGuide: boolean = false;
  selectedCultivation: Cultivation;
  selectedCultivationVariant: CultivationVariant;
  dayPercentage: number = 0;

  constructor(private router: Router,
    private storageService: StorageService,
    private cultivationHierarchyApi: CultivationHierarchyApi,
    private addressToUserApi: AddressToUserApi,
    private userApi: UserApi) { } // Injecting the router and potager service

  async ngOnInit() {

  }

  /**
   * Navigates to the variety cultivation route when a cultivation is selected.
   */
  onCultivationSelected(cultivation: CalendarCultivationListResponse) {
    this.cultivationSelected.emit(cultivation);
  }
}
