import { Component, ElementRef, HostListener } from '@angular/core';
import { EngineIconComponent } from "../../../@icons/engine-icon.component";
import { LogoutIconComponent } from '../../../@icons/logout-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import RoutesConstants from '../../../@commons/constants/routes.constants';
import { AuthApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/auth/auth.api';
import { Router } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'vw-settings-button',
  standalone: true,
  imports: [
    EngineIconComponent,
    LogoutIconComponent,
    TranslateModule,
    NgClass
  ],
  templateUrl: './settings-button.component.html',
  styleUrl: './settings-button.component.scss',
  providers: [{
    provide: AuthApi,
    useClass: AuthApi
  }]
})
export class SettingsButtonComponent {
  showDropdown: boolean = false;
  constructor(private authApi: AuthApi, private router: Router, private eRef: ElementRef) {
    //get email from
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  logout() {
    this.authApi.logout();
    this.router.navigate([RoutesConstants.INIT]);
  }
}
