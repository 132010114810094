<div class="bg-white shadow-lg rounded-lg relative w-max h-max flex flex-col mr-4 py-2 transition-all duration-300 ease-in-out"
    [ngClass]="isOpen ? 'gap-4' : 'gap-0'">
    <div class="flex w-max">
        <div class=" flex items-center gap-2 w-36 sticky left-0 z-10 bg-white px-2 border-r border-grey-50"
            (click)="onParentClick()">
            <div class="w-8 shrink-0">
                <vw-cultivation-item-viewer [cultivation]="hierarchy.cultivations[0]"
                    [displayType]="'TINY'"></vw-cultivation-item-viewer>
            </div>
            <div class="text-xs font-semibold text-primary-800 w-full text-ellipsis">
                {{hierarchy.cultivations.length > 1 ? hierarchy.name : hierarchy.cultivations[0].name}}</div>
            <div class="text-grey-200 absolute right-1 transition-transform duration-300"
                *ngIf="hierarchy.cultivations.length > 1" [ngClass]="{'rotate-90': isOpen, 'rotate-0': !isOpen}">
                <vw-chevron-right-icon></vw-chevron-right-icon>
            </div>
        </div>
        <div class=" w-[calc((100vw-160px)/3)] flex flex-col transition-transform duration-300 justify-center items-center gap-1  "
            [ngClass]="{'pl-4': month === 1, 'pr-4': month === 12}" *ngFor="let month of [1,2,3,4,5,6,7,8,9,10,11,12]">
            <div class="h-1 w-full bg-orange-300 flex items-center justify-center "
                [ngClass]="{'rounded-l-full': month === 1, 'rounded-r-full': month === 12}">
            </div>
            <div class="h-1 w-full bg-green-300 flex items-center justify-center "
                [ngClass]="{'rounded-l-full': month === 1, 'rounded-r-full': month === 12}">
            </div>
        </div>
    </div>
    <div *ngIf="hierarchy.cultivations.length > 1" #expandContent
        class="transition-all duration-300 ease-in-out flex flex-col gap-4 w-max"
        [style.height]="isOpen ? expandContent.scrollHeight + 'px' : '0'" [style.opacity]="isOpen ? '1' : '0'">
        <div class="flex w-max" *ngFor="let cultivation of hierarchy.cultivations">
            <div class="pl-6 pr-2 flex items-center gap-2 w-36 sticky left-0 z-10 bg-white border-r border-grey-50"
                (click)="onChildClick(cultivation)">
                <div class="w-8 shrink-0">
                    <vw-cultivation-item-viewer [cultivation]="cultivation"
                        [displayType]="'TINY'"></vw-cultivation-item-viewer>
                </div>
                <div class="text-xs font-semibold text-primary-800 w-full text-ellipsis">{{cultivation.name}}</div>
            </div>
            <div class=" w-[calc((100vw-160px)/3)] flex flex-col transition-transform duration-300 justify-center items-center gap-1  "
                [ngClass]="{'pl-4': month === 1, 'pr-4': month === 12}"
                *ngFor="let month of [1,2,3,4,5,6,7,8,9,10,11,12]">
                <div class="h-1 w-full bg-orange-300 flex items-center justify-center "
                    [ngClass]="{'rounded-l-full': month === 1, 'rounded-r-full': month === 12}">
                </div>
                <div class="h-1 w-full bg-green-300 flex items-center justify-center "
                    [ngClass]="{'rounded-l-full': month === 1, 'rounded-r-full': month === 12}">
                </div>
            </div>
        </div>
    </div>

</div>