export enum WebViewAction {
	signIn = 'signIn',
	signOut = 'signOut',
	navigate = 'navigate',
	storeValue = 'storeValue',
	readValue = 'readValue',
	deleteValue = 'deleteValue',
	askPermission = 'askPermission',
	permissionAsked = 'permissionAsked',
	tokenReceive = 'tokenReceive',
	vibration = 'vibration',
	scanClicked = 'scanClicked',
}
