<div class="px-2 h-full">
  <vw-card customClasses="bg-white h-full">
    <div class="rounded-xl flex justify-center flex-col  w-full p-[8px]">
      <div class="w-full relative aspect-square grid" [ngClass]="{
          'grid-cols-1 grid-rows-1': hierarchy.cultivations.length === 1,
          'grid-cols-2 grid-rows-2 gap-2': hierarchy.cultivations.length > 1
       }">
        <!-- TODO maybe use this even when there is only one variant and make it full width/height -->
        <vw-cultivation-item-viewer *ngFor="let cultivation of hierarchy.cultivations.slice(0, 3)"
          [cultivation]="cultivation" [displayType]="hierarchy.cultivations.length > 1 ? 'SMALL' : 'BIG'"
          class="w-full h-full">
        </vw-cultivation-item-viewer>
        @if (hierarchy.cultivations.length > 3) {
        <div class="flex items-center text-xl justify-center w-full h-full bg-primary-200 text-white rounded-lg">
          +{{hierarchy.cultivations.length - 3}}
        </div>
        }
        @if ( actionRequired) {
        <div class="flex items-center absolute bottom-[4px] error-msg w-full gap-1">
          <vw-icon-svg iconUrl="/assets/svg/info.svg" additionalClasses="cursor-pointer z-[9] relative ml-2" width="14"
            height="14"></vw-icon-svg>
          <span class="text-[#C5132E] font-semibold text-xs z-[9]">
            {{ 'garden.components.user-hierarchy-item.actionRequired' | translate: {count: 0} }}
          </span>
        </div>
        }
      </div>

      <div class="mt-2 w-full">
        <h3 class="text-xl font-semibold text-[#023E42] truncate">
          {{ hierarchy.name }}
        </h3>
        <p *ngIf="hierarchy && hierarchy.cultivations && hierarchy.cultivations.length > 0"
          class="text-xs font-normal text-[#9DA4A2] flex  items-center text-left justify-start leading-5">
          <vw-icon-svg [iconUrl]="'/assets/svg/garden.svg'" additionalClasses="cursor-pointer z-[9] relative mr-2"
            width="18" height="18"></vw-icon-svg>
          {{ 'garden.components.user-hierarchy-item.cultivations-count' | translate: {count:
          hierarchy.cultivations.length} }}
        </p>
      </div>
    </div>
  </vw-card>
</div>