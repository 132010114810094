import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Address } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/address.entity';
import { LocationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/location-api';
import {
  LocationListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/location-list.request';
import { StorageService } from '../../../@commons/@services/front/storage.service';
import { DatePipe, Location, NgForOf, NgIf } from '@angular/common';
import {
  PagingResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/pagination/paging.response';
import { ProgressBarComponent } from '../../../@commons/@components/progress-bar/progress-bar.component';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocationHomeIconComponent } from '../../../@icons/location-home-icon.component';
import {
  LocationListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/location-list.response';
import { WeatherApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/weather/weather-api';
import { EditUserAdressToastComponent } from './@toasts/edit-user-adress-toast/edit-user-adress-toast.component';
import {ClimateType} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/climate-type.enum';

@Component({
  selector: 'vw-user-address-card',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ProgressBarComponent,
    SharedModule,
    TranslateModule,
    LocationHomeIconComponent,
    DatePipe,
    NgIf,
    NgForOf,
    EditUserAdressToastComponent
  ],
  templateUrl: './user-address-card.component.html',
  styleUrl: './user-address-card.component.scss',
  providers: [
    {
      provide: LocationApi,
      useClass: LocationApi
    }, {
      provide: WeatherApi,
      useClass: WeatherApi
    }
  ]
})
export class UserAddressCardComponent implements OnInit {
  @Input() address: Address;
  @Output() closeEditAddressToast = new EventEmitter<void>();

  locations: LocationListResponse[] = [];
  isEditToastOpen: boolean = false;
  climateType: ClimateType;

  constructor(private locationApi: LocationApi,
    private weatherApi: WeatherApi,
    private storageService: StorageService) {
  }

  ngOnInit() {
    if (this.address && this.address.uid) {
      this.loadLocations();
    }
  }

  private loadLocations() {
    const locationListRequest: LocationListRequest = new LocationListRequest();
    locationListRequest.addressUid = this.address.uid;
    this.locationApi.list(locationListRequest, 0, 20, this.storageService.getCurrentLanguage()).then((res: PagingResponse<any>) => {
      this.locations = res.results;
    });

    const addressUid = this.storageService.getItem('address-uid');
    this.weatherApi.getClimate(addressUid).then((res) => {
      if (res) {
        this.climateType = res.climate;
      }
    });
  }

  onCloseEditToast() {
    this.isEditToastOpen = false;
    this.closeEditAddressToast.emit();
  }

  onEditAddress() {
    this.isEditToastOpen = true;
  }
}
