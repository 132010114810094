import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { HeaderBackBtnComponent } from '../../../../../../@commons/@components/app-header/header-back-btn/header-back-btn.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../../@commons/ui-components/shared.module';
import { FormsModule } from '@angular/forms';
import { LocationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/location-api';
import { TranslateModule } from '@ngx-translate/core';
import { CheckIconComponent } from '../../../../../../@icons/check-icon.component';
import { LocationType } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/location-type.enum';
import { UserHierarchiesComponent } from '../../../../../../garden/user-hierarchies/user-hierarchies.component';
import { SunchineLevels } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/sunchine-levels.enum';
import { SoilType } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/soil-type.enum';
import { Orientation } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/orientation.enum';
import { SurfaceUnit } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/surface-unit.enum';
import { StorageService } from '../../../../../../@commons/@services/front/storage.service';

@Component({
  selector: 'vw-user-adress-location-form',
  standalone: true,
  imports: [HeaderBackBtnComponent, CommonModule, SharedModule, FormsModule, TranslateModule, CheckIconComponent, UserHierarchiesComponent],
  templateUrl: './user-adress-location-form.component.html',
  styleUrl: './user-adress-location-form.component.scss',
  providers: [{
    provide: LocationApi,
    useClass: LocationApi
  },
  ]
})
export class UserAdressLocationFormComponent implements OnChanges, OnInit {
  @Input() location?: any;
  @Output() closeLocationForm = new EventEmitter<void>();
  isSubmittingLocation: boolean = false;

  typeOptions: { label: string, value: string }[] = [
    { label: 'profile.addresses.edit-address.edit-locations.locations.GARDEN', value: 'GARDEN' },
    { label: 'profile.addresses.edit-address.edit-locations.locations.GREENHOUSE', value: 'GREENHOUSE' },
    { label: 'profile.addresses.edit-address.edit-locations.locations.INDOOR', value: 'INDOOR' },
    { label: 'profile.addresses.edit-address.edit-locations.locations.PLANTER_BOX', value: 'PLANTER_BOX' },
  ];

  sunchineOptions: { label: string, value: string }[] = [
    { label: 'profile.addresses.edit-address.edit-locations.sunchine.options.SHADED', value: 'SHADED' },
    { label: 'profile.addresses.edit-address.edit-locations.sunchine.options.PARTIALY_SHADED', value: 'PARTIALY_SHADED' },
    { label: 'profile.addresses.edit-address.edit-locations.sunchine.options.SUNNY', value: 'SUNNY' },
    { label: 'profile.addresses.edit-address.edit-locations.sunchine.options.VERY_SUNNY', value: 'VERY_SUNNY' },
  ];

  name: string = '';
  selectedType: { label: string, value: string } = this.typeOptions[0];
  selectedSunchine: { label: string, value: string } = this.sunchineOptions[0];

  constructor(private locationApi: LocationApi, private storageService: StorageService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['location'] && this.location) {
      this.name = this.location.name;
      this.selectedType = this.typeOptions.find((option: any) => option.value === this.location.locationType);
      this.selectedSunchine = this.sunchineOptions.find((option: any) => option.value === this.location.sunchineLevels);
    }
  }

  ngOnInit() {
    if (this.location) {
      this.name = this.location.name;
      this.selectedType = this.typeOptions.find((option: any) => option.value === this.location.locationType);
      this.selectedSunchine = this.sunchineOptions.find((option: any) => option.value === this.location.sunchineLevels);
    }
  }

  onCloseLocationForm() {
    this.closeLocationForm.emit();
  }

  onTypeDropdownChange(value: { label: string, value: string }) {
    this.selectedType = value;
  }

  onSunchineDropdownChange(value: { label: string, value: string }) {
    this.selectedSunchine = value;
  }

  onNameChange(value: string) {
    this.name = value;
  }


  async onSubmitLocationForm() {
    this.isSubmittingLocation = true;
    if (this.location) {
      await this.locationApi.update({ uid: this.location.uid, name: this.name, locationType: this.selectedType.value as LocationType, sunchineLevels: this.selectedSunchine.value as SunchineLevels });
    } else {
      const addressUid = this.storageService.getItem('address-uid');
      await this.locationApi.create({
        name: this.name,
        locationType: this.selectedType.value as LocationType,
        sunchineLevels: this.selectedSunchine.value as SunchineLevels,
        addressUid,
        soilType: SoilType.ACIDIC,
        surfaceUnit: SurfaceUnit.M2,
        orientation: Orientation.NORTH,
        surface: 10,
      });
      this.location = undefined;
    }
    this.isSubmittingLocation = false;
    this.closeLocationForm.emit();
  }
}
