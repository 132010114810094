<vw-toast-popup [isOpen]="isOpen" height="92%" width="100%" borderRadius="20px"
    customClass="bg-red-500 h-full overflow-auto">
    <vw-close-btn (click)="onClose()" />
    <div class="relative px-4 w-full flex flex-col gap-16 transition-all duration-200 ease-in-out pb-16 h-[92%] overflow-y-auto"
        [ngClass]="isLocationFormOpen ? '-translate-x-full' : 'translate-x-0'">
        <div class="flex flex-col gap-6">
            <div>
                <h2 class="text-[#062825] text-2xl font-semibold pb-3">{{ "profile.addresses.edit-address.title" |
                    translate
                    }}</h2>
                <span class="text-xs font-normal text-[#9BA6A1] pb-2 flex">{{'onboarding.questions.address.input.label'
                    |
                    translate}}</span>
                <vw-location-input [(value)]="addressInput" (suggestionSelected)="onSelectFeature($event)"
                    [placeholder]="'onboarding.questions.address.input.placeholder'"
                    class="type-date"></vw-location-input>
            </div>
            <vw-button class="mx-auto" (click)="onSaveAddress()"
                label="{{ 'profile.addresses.edit-address.button' | translate }}" [iconPosition]="'left'"
                [color]="'bg-primary hover:bg-black'" [loading]="isUpdatingAddress" [iconTemplate]="iconTemplate"
                [textColor]="'text-white hover:text-white'" customClass="text-center mx-auto">
                <ng-template #iconTemplate>
                    <vw-check-icon></vw-check-icon>
                </ng-template>
            </vw-button>
        </div>
        <div>
            <h2 class="text-[#062825] text-2xl font-semibold pb-3">{{
                "profile.addresses.edit-address.edit-locations.title" |
                translate
                }}</h2>
            <span class="text-xs font-normal text-[#9BA6A1] pb-2 flex">{{
                "profile.addresses.edit-address.edit-locations.description" |
                translate
                }}
            </span>
            <div class="flex flex-col gap-4 pt-4">
                <vw-user-adress-location-card *ngFor="let location of locations" [location]="location"
                    (editLocation)="onEditLocation($event)">
                </vw-user-adress-location-card>
                <vw-button class="mx-auto" (click)="onCreateLocation()"
                    label="{{ 'profile.addresses.edit-address.create-location.button' | translate }}"
                    [iconPosition]="'left'" [color]="'bg-primary hover:bg-black'" [iconTemplate]="iconTemplate"
                    [textColor]="'text-white hover:text-white'" customClass="text-center mx-auto">
                    <ng-template #iconTemplate>
                        <vw-plus-icon></vw-plus-icon>
                    </ng-template>
                </vw-button>
            </div>
        </div>
    </div>
    <div class="absolute top-9 px-4 bg-white w-full left-0 transition-all duration-200 ease-in-out"
        [ngClass]="isLocationFormOpen ? 'translate-x-0' : 'translate-x-full'">
        <vw-user-adress-location-form (closeLocationForm)="onCloseLocationForm()"
            [location]="currentLocation"></vw-user-adress-location-form>
    </div>
</vw-toast-popup>