import { Component } from '@angular/core';

@Component({
  selector: 'vw-account-icon',
  standalone: true,
  imports: [],
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2693_13963)">
  <path d="M20 22H4V21C4 17.6863 6.68629 15 10 15H14C17.3137 15 20 17.6863 20 21V22Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  <path d="M8 22V16" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  <path d="M16 22V16" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  <rect x="8" y="2" width="8" height="14" rx="4" fill="white" stroke="currentColor" stroke-width="2"/>
  <path d="M17.2614 9.68466L19.1493 9.21268C19.6493 9.08769 20 8.63846 20 8.12311C20 7.50283 19.4972 7 18.8769 7L16.1577 7C16.053 7 15.9484 7.01289 15.8469 7.03829C13.3241 7.66897 10.6759 7.66897 8.15314 7.03829C8.05155 7.01289 7.94716 7 7.84245 7L5.12309 7C4.50282 7 4 7.50283 4 8.12311C4 8.63846 4.35074 9.08769 4.85071 9.21268L6.73863 9.68466C10.1931 10.5483 13.8069 10.5483 17.2614 9.68466Z" fill="white" stroke="currentColor" stroke-width="2"/>
  </g>
  <defs>
  <clipPath id="clip0_2693_13963">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>`,  
})
export class AccountIconComponent {

}
