import { Component } from '@angular/core';

@Component({
  selector: 'vw-corner-left-down-icon',
  standalone: true,
  imports: [],
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-corner-left-down-icon lucide-corner-left-down"><polyline points="14 15 9 20 4 15"/><path d="M20 4h-7a4 4 0 0 0-4 4v12"/></svg>
  `,
})
export class CornerLeftDownIconComponent {

}
