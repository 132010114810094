import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedModule} from '../../../@commons/ui-components/shared.module';
import {TranslateModule} from "@ngx-translate/core";
import {User} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/user.entity';
import {Router} from '@angular/router';
import RoutesConstants from '../../../@commons/constants/routes.constants';
import {JsonPipe, NgIf} from '@angular/common';

@Component({
  selector: 'vw-header-home',
  standalone: true,
  imports: [
    SharedModule,
    TranslateModule,
    NgIf,
    JsonPipe
  ],
  templateUrl: './header-home.component.html',
  styleUrl: './header-home.component.scss'
})
export class HeaderHomeComponent {
  @Input() user: User;
  @Output() onProfileClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {
    //get email from data
  }

  handlerClickOnUserGreeting($event: MouseEvent) {
    this.router.navigate([RoutesConstants.PROFILE]);
  }
}
