<div class="flex flex-col h-full">
  <div class="grow overflow-y-auto px-4" #scrollMe>
    <vw-ai-chatbot-discussion
      [messages]="messages"
      [botLoading]="willyLoading"
      (messageSubmitted)="onMessageSubmitted($event)"
    ></vw-ai-chatbot-discussion>
  </div>
  <div
    class="bg-white shadow-[0px_-5px_20px_rgba(38,41,57,0.12)] w-full flex flex-col gap-3 h-content py-4"
    [ngClass]="{ 'fixed bottom-0 left-0 right-0 z-[999]': inputIsFocus }"
  >
    <vw-ai-chatbot-input-message
      (messageSubmitted)="onMessageSubmitted($event)"
      (inputFocus)="onInputFocus()"
      (inputBlur)="onInputBlur()"
    ></vw-ai-chatbot-input-message>
    <!--<vw-ai-chatbot-suggestions (suggestionClicked)="onSuggestionClicked($event)" [suggestions]="suggestions"></vw-ai-chatbot-suggestions>-->
  </div>
</div>
