import {Component, HostListener, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MockConfig} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/config/mock-config';
import {SdkConfigService} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/config/sdk-config.service';
import {environment} from '../environments/environment';
import {StorageHelper} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/@common/helpers/storage.helper';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import {AppLoaderComponent} from './@commons/@components/loaders/app-loader/app-loader.component';
import {StorageService} from './@commons/@services/front/storage.service';
import {UserApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';

// Enregistre les langues nécessaires;

registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeNl);
registerLocaleData(localeDe);
registerLocaleData(localeIt);
registerLocaleData(localeEs);

@Component({
  selector: 'vw-root',
  standalone: true,
  imports: [RouterOutlet, AppLoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    {
      provide: UserApi,
      useClass: UserApi
    }
  ]
})
export class AppComponent implements OnInit {
  lastTouchEnd = 0;

  constructor(private translate: TranslateService, private storageService: StorageService) {
    const availableLanguages = ['fr', 'en', 'nl', 'de', 'it', 'es'];
    this.translate.addLangs(availableLanguages);
    this.translate.setDefaultLang('fr');
    // Récupérer la langue stockée (si existante)
    const storedLang = this.storageService.getCurrentLanguage()?.toLowerCase();
    // Détecter la langue du navigateur
    const browserLang = navigator.language.split('-')[0]; // Ex: "en-US" → "en"

    // Vérifier si la langue stockée est valide, sinon utiliser celle du navigateur, sinon "fr"
    const userLang = availableLanguages.includes(storedLang)
      ? storedLang
      : availableLanguages.includes(browserLang)
        ? browserLang
        : 'fr';

    // Stocker la langue sélectionnée pour éviter de la redétecter à chaque fois
    this.storageService.setCurrentLang(userLang.toUpperCase());

    // Appliquer la langue choisie
    this.translate.use(userLang);
  }


  @HostListener('touchend', ['$event'])
  preventDoubleTapZoom(event
                         :
                         TouchEvent
  ) {
    const now = new Date().getTime();
    if (now - this.lastTouchEnd <= 300) { // 300ms pour bloquer le zoom sur double tap
      event.preventDefault();
    }
    this.lastTouchEnd = now;
  }

  ngOnInit()
    :
    void {
    const mockConfig
      :
      MockConfig = {
      dms: true,
      tasks: false,
      plants: false,
      cultivations: false,
      'cultivation-periods': false,
      'botanic-families': true,
      'growing-steps': false,
      'plants-to-growing-steps': false,
      'user-suggestions': false,
      risks: true,
      social: true,
      user: false,
      users: false,
      weather: false,
      locations: false,
      notifications: true
    };
    SdkConfigService.configure({
      baseUrl: environment.API_BASE,
      isAdmin: false,
      apiKey: "",
      mockConfig,
      storeOnMobile: true,
      accessTokenName: "access_token",
      refreshTokenName: "refresh_token",
      addressName: 'address-uid',
    });

    StorageHelper.getInstance().getMobileStorageItem('access_token').then((res) => {
      StorageHelper.getInstance().setItem('access_token', res || '');
    });
    StorageHelper.getInstance().getMobileStorageItem('refresh_token').then((res) => {
      StorageHelper.getInstance().setItem('refresh_token', res || '');
    });
  }
}
