import { CommonModule } from '@angular/common';
import {
  Component, EventEmitter,
  Input, LOCALE_ID, OnInit, Output,
} from '@angular/core';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import {
  CollapsibleCardComponent
} from '../../../@commons/@components/cards/collapsible-card/collapsible-card.component';
import {
  MoonStatusResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/moon-status.response';
import { WeatherApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/weather/weather-api';
import { TranslateModule } from '@ngx-translate/core';
import {
  WeatherStatusResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/weather-status.response';
import { SkeletonLoaderComponent } from '../../../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import {HexToHsl} from '../../../@commons/pipes/HexToHsl';
import {ChevronRightIconComponent} from '../../../@icons/moon-icon.component';

@Component({
  selector: 'vw-moon-info-card',
  standalone: true,
  imports: [CommonModule, SharedModule, CollapsibleCardComponent, TranslateModule, SkeletonLoaderComponent, HexToHsl, ChevronRightIconComponent],
  templateUrl: './moon-info-card.component.html',
  styleUrl: './moon-info-card.component.scss',
  providers: [
    {
      provide: WeatherApi,
      useClass: WeatherApi
    },
    { provide: LOCALE_ID, useValue: 'fr' }
  ]
})
export class MoonInfoCardComponent implements OnInit {

  @Input() labelClass: string = ''; // labelClass of the card for css


  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // Event emitter to notify parent component about the state change
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>(); // Event for card click handling

  weatherImg: string = '/assets/svg/moon/moon-tree.svg';
  iconUrl: string = '/assets/svg/moon/moon.svg';
  currentDate: Date = new Date();
  backgroundColor = '#051038';
  moonData: MoonStatusResponse;
  currentWeather: WeatherStatusResponse = null;
  isLoading: boolean = true;

  constructor(private weatherApi: WeatherApi) {
  }
  async ngOnInit() {
    await Promise.all([this.loadMoonInfo(), this.loadWeatherInfo()]);
    this.isLoading = false;
  }

  async loadWeatherInfo() {
    this.currentWeather = await this.weatherApi.getStatus();
  }

  async loadMoonInfo() {
    this.moonData = await this.weatherApi.getMoonStatus();
    console.log(this.moonData);
  }
}
