<vw-header-padding-safe-area></vw-header-padding-safe-area>
<div class="pt-16 overflow-hidden">
  <!-- Page title -->
  <h2 class="text-[#062825] text-[32px] font-semibold pb-3 p-4">{{ 'calendar.title' | translate }}</h2>

  <vw-calendar-hierarchies [hierarchies]="currentMonthCalendarItems" [isLoading]="isLoading"
    (cultivationSelected)="onCultivationSelected($event)">

  </vw-calendar-hierarchies>

  <vw-cultivation-guide-toast [isOpen]="isShowingGuide" [cultivation]="selectedCultivation"
    [cultivationVariant]="selectedVariant" (close)="isShowingGuide = false">
  </vw-cultivation-guide-toast>
</div>