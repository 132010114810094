import { Component } from '@angular/core';

@Component({
  selector: 'vw-garden-icon',
  standalone: true,
  imports: [],
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.2111 22H3.78894C3.31779 22 2.89951 21.6985 2.75052 21.2515L2.57262 20.7179C2.24405 19.7322 2.69029 18.6549 3.61962 18.1902C3.85861 18.0707 4.02599 17.8441 4.06992 17.5805L4.16054 17.0368C4.32515 16.0491 5.42344 15.5277 6.2928 16.0245L8 17L8.66667 16C9.30096 15.0486 10.699 15.0486 11.3333 16C11.6505 16.4757 12.3495 16.4757 12.6667 16C13.301 15.0486 14.699 15.0486 15.3333 16L15.5 16.25C15.7761 16.6642 16.3358 16.7761 16.75 16.5L17.0012 16.3325C18.0078 15.6614 19.3771 16.1312 19.7597 17.279L19.9383 17.815C19.9778 17.9335 20.0627 18.0314 20.1744 18.0872C21.2219 18.611 21.7249 19.8253 21.3545 20.9364L21.2495 21.2515C21.1005 21.6985 20.6822 22 20.2111 22Z" stroke="currentColor" stroke-width="2"/>
  <path d="M8 18V2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 18V4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.10377 9.55189L6.80824 10.4041C7.24803 10.624 7.73414 10.2024 7.57865 9.73595L7.04612 8.13838C6.71142 7.13427 5.83814 6.40545 4.79036 6.25577C3.9678 6.13826 3.30915 6.92746 3.57191 7.71573L3.60976 7.82927C3.85963 8.57888 4.39704 9.19852 5.10377 9.55189Z" stroke="currentColor" stroke-width="2"/>
  <path d="M13.1038 12.5519L14.8082 13.4041C15.248 13.624 15.7341 13.2024 15.5786 12.7359L15.0461 11.1384C14.7114 10.1343 13.8381 9.40545 12.7904 9.25577C11.9678 9.13826 11.3092 9.92746 11.5719 10.7157L11.6098 10.8293C11.8596 11.5789 12.397 12.1985 13.1038 12.5519Z" stroke="currentColor" stroke-width="2"/>
  <path d="M10.8962 6.55189L9.19176 7.40412C8.75197 7.62401 8.26586 7.20242 8.42135 6.73595L8.95388 5.13838C9.28858 4.13427 10.1619 3.40545 11.2096 3.25577C12.0322 3.13826 12.6908 3.92746 12.4281 4.71573L12.3902 4.82927C12.1404 5.57888 11.603 6.19852 10.8962 6.55189Z" stroke="currentColor" stroke-width="2"/>
  <path d="M18.8962 9.55189L17.1918 10.4041C16.752 10.624 16.2659 10.2024 16.4214 9.73595L16.9539 8.13838C17.2886 7.13427 18.1619 6.40545 19.2096 6.25577C20.0322 6.13826 20.6908 6.92746 20.4281 7.71573L20.3902 7.82927C20.1404 8.57888 19.603 9.19852 18.8962 9.55189Z" stroke="currentColor" stroke-width="2"/>
  </svg>
`,  
})
export class GardenIconComponent {

}
