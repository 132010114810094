<vw-header-padding-safe-area></vw-header-padding-safe-area>
<div class="p-4 pb-28 pt-16">
  <div class="flex items-baseline justify-between pb-3">
    <h2 class="flex items-center w-auto gap-2 text-[#062825] text-[32px] font-semibold pb-3">{{"profile.title" |
      translate}}</h2>
    <vw-settings-button></vw-settings-button>
  </div>
  <div>
    <div class="flex items-center flex-col gap-8 w-auto mb-4">
      <div class="w-full">
        <vw-card customClasses="bg-white" *ngIf="user && !isLoading">
          <div class="bg-white shadow-lg py-4 pl-4 pr-2 rounded-xl flex items-start gap-6">
            <div class="w-24 h-24">
              <vw-img [rounded]="true" class="w-24 h-24" [src]="userPictureUrl"></vw-img>
            </div>
            <div class="w-account">
              <div class="relative flex items-center justify-between w-full">
                <h3 class="text-xl text-primary-dark font-semibold pb-2">{{"profile.infos.title" | translate}}</h3>
                <div class="absolute right-0 top-0 items-end justify-end ">
                  <vw-icon-svg (click)="editUserMode = true" iconUrl="/assets/svg/square-pen.svg"
                    additionalClasses="cursor-pointer" width="24px" height="24px"></vw-icon-svg>
                </div>
              </div>
              <div class="w-full pb-3">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.pseudo" | translate}}</p>
                <p class="text-sm text-primary-dark">{{user.pseudo}}</p>
              </div>
              <div class="w-full pb-3">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.joined" | translate}}</p>
                <p class="text-sm text-primary-dark">{{user.registrationDate | date: 'MM/yyyy' }}</p>
              </div>
              <div class="w-full pb-3">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.user-type" | translate}}</p>
                <p class="text-sm text-primary-dark">{{'profile.infos.user-types.' + user.expertise | translate}}</p>
              </div>
              <div class="w-full">
                <p class="text-[#9BA6A1] text-xs">{{"profile.infos.language" | translate}}</p>
                <p class="text-sm text-primary-dark">{{"profile.infos.languages." + user.preferredLanguage | translate}}
                </p>
              </div>
            </div>
          </div>
        </vw-card>
        <vw-skeleton-loader *ngIf="isLoading" [width]="'100%'" [height]="'248px'"
          [borderRadius]="'0.75rem'"></vw-skeleton-loader>
      </div>
      <div class="w-full relative">
        <div class="absolute top-1 right-3 rotate-6 flex gap-1 text-primary-900">
          <vw-corner-left-down-icon class="mt-1.5"></vw-corner-left-down-icon>
          <span class="mb-12 uppercase text-sm font-medium" style="font-family: 'jost'">Coming
            soon</span>
        </div>
        <h2 class="text-[#062825] text-2xl font-semibold pb-3 opacity-30">
          {{ "profile.level" | translate}}
        </h2>
        <div class="bg-white shadow-lg p-3 rounded-xl opacity-30">
          <div class="flex items-center gap-2 w-full pb-3">
            <vw-icon-svg iconUrl="/assets/svg/leafonly.svg" additionalClasses="cursor-pointer" width="20px"
              height="20px"></vw-icon-svg>
            <p class="text-base text-primary-dark">Jeune pousse</p>
          </div>
          <vw-progress-bar [progress]="progress"></vw-progress-bar>
        </div>

      </div>
      <div class="w-full overflow-hidden">
        <h2 class="text-[#062825] text-2xl font-semibold pb-3">{{ "profile.garden.title" | translate }}</h2>
        <div *ngIf="!isLoading" class="flex items-center flex-wrap gap-y-4 w-full">
          <vw-user-address-card *ngFor="let address of userAddresses" class="w-full" [address]="address"
            (closeEditAddressToast)="onCloseEditAddressToast()">
          </vw-user-address-card>
        </div>
        <vw-skeleton-loader *ngIf="isLoading" [width]="'100%'" [height]="'160px'"
          [borderRadius]="'0.75rem'"></vw-skeleton-loader>
      </div>
    </div>
  </div>
</div>

<vw-edit-user-toast [isOpen]="editUserMode" [user]="user" (close)="onCloseEditUser($event)"></vw-edit-user-toast>

<!--
<vw-custom-form  [isOpen]="editUserMode"
[formConfig]="formConfig"
(closeBottomSheet)="closeBottomSheet()"
(submitForm)="handleFormSubmit($event)"></vw-custom-form> -->