import { Component, EventEmitter, Output, signal } from '@angular/core';
import { SharedModule } from '../../../ui-components/shared.module';
import { AppMenuItemComponent } from '../../../@components/app-menu/app-menu-item/app-menu-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { ScanBarcodeIconComponent } from '../../../../@icons/scan-barcode-icon.component';
import { CiclePlusIconComponent } from '../../../../@icons/circle-plus-icon.component';
import { CarrotIconComponent } from '../../../../@icons/carrot-icon.component';
import { EarthIconComponent } from '../../../../@icons/earth-icon.component';
import { TaskIconComponent } from '../../../../@icons/task-icon.component';
import { WebViewBridgeService } from '../../../@services/webview/webview-bridge.service';
import { WebViewAction } from '../../../enums/webview-action';

@Component({
  selector: 'vw-modal-ai-actions-buttons',
  standalone: true,
  imports: [
    SharedModule,
    AppMenuItemComponent,
    TranslateModule,
    ScanBarcodeIconComponent,
    CiclePlusIconComponent,
    CarrotIconComponent,
    EarthIconComponent,
    TaskIconComponent,
  ],
  templateUrl: './modal-ai-actions-buttons.component.html',
  styleUrl: './modal-ai-actions-buttons.component.scss',
})
export class ModalAiActionsButtonsComponent {
  constructor(private webViewBridgeService: WebViewBridgeService) {}

  @Output() showMap = new EventEmitter();
  @Output() addPlant = new EventEmitter();
  @Output() scan = new EventEmitter();
  @Output() showTasks = new EventEmitter();
  showTasksClicked() {
    this.showTasks.emit();
  }

  showMapClicked() {
    this.showMap.emit();
  }

  addClicked() {
    this.addPlant.emit();
  }

  scanClicked() {
    this.webViewBridgeService.postMessageToChannel(
      WebViewAction.scanClicked,
      'true'
    );
    this.scan.emit();
  }
}
