<div class="flex items-center justify-between pb-6">
  <div class="flex items-center gap-2" (click)="onProfileClick.emit();">
    <!--<vw-icon-svg
      iconUrl="{{
          user.profileImage == undefined
            ? '/assets/svg/user.svg'
            : user.profileImage
        }}"
      additionalClasses=""
      width="48"
      height="48"
    ></vw-icon-svg>-->
    <div (click)="handlerClickOnUserGreeting($event)">
      <p class="text-[#7A8581] text-sm" *ngIf="user && user.pseudo" [innerHTML]="'home.header.greeting' | translate: {firstName: user.pseudo}"></p>
      <p class="text-[#7A8581] text-sm" *ngIf="!user || !user.pseudo" [innerHTML]="'home.header.greeting-guest' | translate"></p>
    </div>
  </div>
  <vw-icon-svg
    iconUrl="/assets/svg/bell.svg"
    additionalClasses="cursor-pointer"
    width="40"
    height="40"
  ></vw-icon-svg>
</div>
