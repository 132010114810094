var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { BaseApi } from '../@common/base-api';
export class AiApi extends BaseApi {
  constructor() {
    super('ai');
  }
  chatWithWilly(chatRequest) {
    const _super = Object.create(null, {
      post: {
        get: () => super.post
      }
    });
    return __awaiter(this, void 0, void 0, function* () {
      return _super.post.call(this, `willy`, chatRequest);
    });
  }
}