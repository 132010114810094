import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { CalendarCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';
import { CultivationItemViewerComponent } from '../../../garden/@components/hierarchy-item/cultivation-item-viewer/cultivation-item-viewer.component';
import { ChevronRightIconComponent } from '../../../@icons/chevron-right.component';
import { CommonModule } from '@angular/common';
import { CalendarCultivationListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';
@Component({
  selector: 'vw-calendar-hierarchy-item',
  standalone: true,
  imports: [CultivationItemViewerComponent, ChevronRightIconComponent, CommonModule],
  templateUrl: './calendar-hierarchy-item.component.html',
  styleUrl: './calendar-hierarchy-item.component.scss'
})
export class CalendarHierarchyItemComponent {
  @Input() hierarchy: CalendarCultivationHierarchyListResponse;
  @Input() currentMonth: number;
  @Input() scrollX: number;
  @Input() dayPercentage: number;

  @Output() cultivationSelected = new EventEmitter<CalendarCultivationListResponse>();

  @ViewChild('expandContent') expandContent: ElementRef;

  isOpen: boolean = false;

  onParentClick() {
    if (this.hierarchy.cultivations.length > 1) {
      this.isOpen = !this.isOpen;
    } else {
      this.cultivationSelected.emit(this.hierarchy.cultivations[0]);
    }
  }

  onChildClick(cultivation: CalendarCultivationListResponse) {
    this.cultivationSelected.emit(cultivation);
  }
}
