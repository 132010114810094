<div>
<!-- agenda-archives.component.html -->
<div *ngFor="let task of tasks">
  <vw-card customClasses="bg-white rounded-xl flex gap-4 p-4 mb-3">
    <!-- Task icon -->
    <vw-icon-svg
      [iconUrl]="task.taskState === 'DONE' ? '/assets/svg/task.svg' : ''"
      additionalClasses="cursor-pointer"
      width="18"
    ></vw-icon-svg>

    <div>
      <!-- Task name -->
      <p class="text-lg font-semibold leading-none pb-[2px] text-[#0C0D0D]">
        {{ task.name }}
      </p>
      <!-- Task description -->
      <p class="text-[#7A8581] text-sm">
        {{ task.shortDescription }}
      </p>

      <!-- Task metadata icons and labels -->
      <!--<div class="flex items-center gap-1 mt-2">
        <div
          *ngFor="let meta of task.metadata"
          [class]="
            'text-xs font-semibold flex items-center gap-2 py-1 px-2 w-auto rounded-2xl' +
            ' ' + meta.color + ' border ' + meta.borderColor
          "
        >
          <vw-icon-svg *ngIf="meta.iconUrl"
            [iconUrl]="meta.iconUrl"
            additionalClasses="cursor-pointer"
            width="14"
          ></vw-icon-svg>
          {{ meta.label }}
        </div>
      </div>-->
    </div>
  </vw-card>
</div>
 <!-- advice.component.html -->
  <div class="mt-3">
    <h1 class="font-semibold text-2xl">{{'agenda.tabs.previous.advices' | translate}}</h1>
  </div>

  <vw-article *ngFor="let advice of advices" [article]="advice"></vw-article>

  </div>
