import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { CultivationGuideComponent } from '../../cultivation-guide/cultivation-guide.component';
import { BaseModalComponent } from '../../../@commons/ui-components/modal/base-modal-component';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { CultivationVariantViewerComponent } from '../../@components/cultivation-item/cultivation-variant-viewer/cultivation-variant-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { CultivationVariant } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import { CloseBtnComponent } from '../../../@commons/@components/close-btn/close-btn.component';
import { MyCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-hierarchy-list.response';
import { Cultivation } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';
import { CultivationItemViewerComponent } from '../../@components/hierarchy-item/cultivation-item-viewer/cultivation-item-viewer.component';
import { CalendarCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';
import { CalendarCultivationListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';

@Component({
  selector: 'vw-hierarchy-items-choice-modal',
  standalone: true,
  imports: [
    SharedModule,
    CultivationGuideComponent,
    NgIf,
    CultivationVariantViewerComponent,
    NgForOf,
    TranslateModule,
    NgClass,
    CloseBtnComponent,
    CultivationItemViewerComponent,
  ],
  templateUrl: './hierarchy-items-choice-modal.component.html',
  styleUrl: './hierarchy-items-choice-modal.component.scss',
})
export class HierarchyItemsChoiceModalComponent
  extends BaseModalComponent
  implements OnChanges
{
  @Input() hierarchy: any;
  @Output() onCultivationSelected = new EventEmitter<
    Cultivation | CalendarCultivationListResponse
  >(); // Emit event when cultivation is selected
  imageUrl: string = '';
  constructor() {
    super();
  }

  onSelectCultivation(cultivation: Cultivation) {
    this.onCultivationSelected.emit(cultivation); // Emit event when cultivation is selected
  }

  ngOnChanges() {
    //TODO - Implement this method
    if (this.hierarchy && (this.hierarchy as any).files?.length > 0) {
      this.imageUrl = (this.hierarchy as any).files[
        (this.hierarchy as any).files.length - 1
      ].cdnUrl;
    }
  }
}
