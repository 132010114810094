import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../@commons/ui-components/shared.module';
import { fadeIn } from '../@commons/animations/animations';
import {
  HeaderPaddingSafeAreaComponent
} from "../@commons/@components/header-padding-safe-area/header-padding-safe-area.component";
import { CalendarCultivationHierarchyRequest } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/cultivation/calendar-cultivation-hierarchy-list.request';
import { CalendarCultivationListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';
import { CalendarCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';
import { CultivationVariantListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/cultivation-variant-list.response';
import { PlantApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/plant/plant-api';
import { CultivationHierarchyApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import { StorageService } from '../@commons/@services/front/storage.service';
import moment from 'moment';
import { CalendarHierarchiesComponent } from './@components/calendar-hierarchies/calendar-hierarchies.component';
import { ChevronRightIconComponent } from '../@icons/chevron-right.component';
import { CultivationGuideToastComponent } from '../garden/@toasts/cultivation-guide-toast/cultivation-guide-toast.component';
import { CultivationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-api';
import { CultivationResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/cultivation.response';
import { CultivationVariantApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-variant/cultivation-variant-api';


@Component({
  selector: 'vw-calendar',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterOutlet, TranslateModule, HeaderPaddingSafeAreaComponent, CalendarHierarchiesComponent, ChevronRightIconComponent, CultivationGuideToastComponent],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
  animations: [fadeIn],
  providers: [{
    provide: CultivationHierarchyApi,
    useClass: CultivationHierarchyApi
  }, {
    provide: PlantApi,
    useClass: PlantApi
  }, {
    provide: CultivationApi,
    useClass: CultivationApi
  }, {
    provide: CultivationVariantApi,
    useClass: CultivationVariantApi
  }]
})
export class CalendarComponent implements OnInit {
  isLoading = false;
  currentMonthCalendarItems: CalendarCultivationHierarchyListResponse[] = [];
  isShowingGuide = false;
  selectedCultivation: CultivationResponse;
  selectedVariant: CultivationVariantListResponse;

  constructor(
    private cultivationHierarchyApi: CultivationHierarchyApi,
    private cultivationApi: CultivationApi,
    private cultivationVariantApi: CultivationVariantApi,
    private storageService: StorageService) { }

  async ngOnInit() {
    await this.loadCalendar();
  }

  async loadCalendar() {
    this.isLoading = true;
    const calendarRequest: CalendarCultivationHierarchyRequest = new CalendarCultivationHierarchyRequest();
    calendarRequest.addressUid = this.storageService.getItem('address-uid');
    this.currentMonthCalendarItems = await this.cultivationHierarchyApi.calendar(calendarRequest, this.storageService.getCurrentLanguage());
    this.isLoading = false;
  }

  async onCultivationSelected(cultivation: CalendarCultivationListResponse) {
    console.log(cultivation);
    const cultivationResponse = await this.cultivationApi.find(cultivation.uid, this.storageService.getCurrentLanguage());
    this.selectedCultivation = cultivationResponse;
    this.isShowingGuide = true;
    const variantResponse = await this.cultivationVariantApi.list({ cultivationUid: cultivation.uid }, 0, 1, this.storageService.getCurrentLanguage());
    this.selectedVariant = variantResponse.results[0];
  }
}
