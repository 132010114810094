import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {TaskComponent} from '../../@commons/@components/task/task.component';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {ArticleComponent} from "../../@commons/@components/article/article.component";
import {TaskApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/task/task-api';
import {
  TaskListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/task-list.request';
import {StorageService} from '../../@commons/@services/front/storage.service';
import {
  TaskListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/task-list.response';

@Component({
  selector: 'vw-agenda-task', // Component selector for app-agenda-task
  standalone: true, // Indicates the component is standalone
    imports: [CommonModule, SharedModule, TranslateModule, TaskComponent, ArticleComponent], // Modules required by this component
  templateUrl: './agenda-task.component.html', // HTML template for the component
  styleUrl: './agenda-task.component.scss', // Stylesheet for the component
  providers: [{
    provide: TaskApi,
    useClass: TaskApi
  }]
})
export class AgendaTaskComponent {

  tasks: TaskListResponse[] = [];

  /**
   * ID of the currently active task.
   * @type {number | null}
   */
  activeTaskId: number | null = null;

  /**
   * List of advices related to the agenda.
   * @type {any[]}
   */
  advices: any[] = [];

  /**
   * Initializes the AgendaTaskComponent with required services.
   * @param {TaskApi} taskApi - Service to fetch agenda-related data
   */
  constructor(private taskApi: TaskApi, private storageService: StorageService) {}

  ngOnInit() {
    this.loadTasks();
    this.loadArticles();
  }

  loadTasks() {
    const listTasksRequest = new TaskListRequest();
    //listTasksRequest.addressUid = '';
    this.taskApi.list(listTasksRequest, 0, 20, this.storageService.getCurrentLanguage()).then((response) => {
      this.tasks = response.results;
    });
  }

  loadArticles() {
    /*this.agendaService.getAgendaAdvice().subscribe((articles) => {
      this.articles = articles;
    });  */
  }
  /**
   * Sets the selected task, ensuring only one task is active at a time.
   * @param {number} taskId - ID of the task to be set as active.
   */
  selectTask(taskId: number): void {
    this.activeTaskId = taskId;
  }
}
