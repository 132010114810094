<div class="bg-white shadow-lg p-4 rounded-xl w-full flex justify-between items-center" (click)="onClickLocation()"
    [ngClass]="{
    'border border-primary-300': clickedLocation
  }">
    <div class="flex flex-col gap-1">
        <p class="text-primary-900 text-xl font-semibold">{{location.name}}</p>
        <div class="flex items-center gap-2 text-primary-900 text-sm">
            <vw-location-home-icon width="16" height="16"></vw-location-home-icon>
            <p class="text-grey-400">{{'profile.addresses.edit-address.edit-locations.locations.' +
                location.locationType | translate}}</p>
        </div>
    </div>
    <div class="text-primary-700">
        <vw-chevron-right-icon></vw-chevron-right-icon>
    </div>
</div>