<div>
  <vw-calendar-hierarchies [hierarchies]="currentMonthCalendarItems" [isLoading]="isLoading"
    (cultivationSelected)="onCultivationSelected($event)">

  </vw-calendar-hierarchies>
</div>



<vw-cultivation-guide-toast [isOpen]="isShowingGuide" [cultivation]="selectedCultivation"
  [cultivationVariant]="selectedVariant" (close)="isShowingGuide = false">
</vw-cultivation-guide-toast>
