import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AiChatbotDiscussionComponent,
  Message,
} from './ai-chatbot-discussion/ai-chatbot-discussion.component';
import { AiChatbotInputMessageComponent } from './ai-chatbot-input-message/ai-chatbot-input-message.component';
import { AiChatbotSuggestionsComponent } from './ai-chatbot-suggestions/ai-chatbot-suggestions.component';
import { CommonModule } from '@angular/common';
import { AiApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/ai/ai-api';

@Component({
  selector: 'vw-ai-chatbot',
  standalone: true,
  imports: [
    AiChatbotDiscussionComponent,
    AiChatbotInputMessageComponent,
    AiChatbotSuggestionsComponent,
    CommonModule,
  ],
  providers: [
    {
      provide: AiApi,
      useClass: AiApi,
    },
  ],
  templateUrl: './ai-chatbot.component.html',
  styleUrl: './ai-chatbot.component.scss',
})
export class AiChatbotComponent {
  inputIsFocus = false;
  @ViewChild('scrollMe') private discussionContainer!: ElementRef;
  @Output() inputFocus = new EventEmitter<void>();
  @Output() inputBlur = new EventEmitter<void>();

  chatSessionId: string | undefined;
  willyLoading = false;

  constructor(private aiApi: AiApi) {}

  ngOnInit() {
    this.scrollDiscussionToBottom();
  }

  sendMessageToWilly(message: string) {
    if (!this.willyLoading) {
      this.willyLoading = true;
      this.aiApi
        .chatWithWilly({
          text: message,
          sessionId: this.chatSessionId,
        })
        .then((willyResponse) => {
          this.chatSessionId = willyResponse.sessionId;
          this.messages.push({
            message: willyResponse.text,
            isUser: false,
            dateTime: new Date().getTime(),
          });
          this.scrollDiscussionToBottom();
        })
        .catch((error) => {
          this.messages.push({
            message:
              "Sorry, I can't connect to Willy right now. Please try again later.",
            isUser: false,
            dateTime: new Date().getTime(),
          });
        })
        .finally(() => {
          this.willyLoading = false;
        });
    }
  }

  scrollDiscussionToBottom(): void {
    setTimeout(() => {
      this.discussionContainer.nativeElement.scroll({
        top: this.discussionContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  onMessageSubmitted(message: string) {
    this.addMessage(message);
  }

  onSuggestionClicked(suggestion: string) {
    this.addMessage(suggestion);
  }

  addMessage(message: string) {
    this.messages.push({
      message: message,
      isUser: true,
      dateTime: new Date().getTime(),
    });
    this.scrollDiscussionToBottom();
    this.sendMessageToWilly(message);
  }

  onInputFocus() {
    this.inputIsFocus = true;
    this.inputFocus.emit();
  }

  onInputBlur() {
    this.inputIsFocus = false;
    this.inputBlur.emit();
  }

  messages: Message[] = [
    {
      message: 'Hey, I’m Willy! What can I do for you today?',
      isUser: false,
      dateTime: new Date().getTime(),
    },
  ];

  suggestions: string[] = [
    'When should I water my plants?',
    'How much water should I give my plants?',
    'How often should I water my plants?',
    'When should I fertilize my plants?',
  ];
}
