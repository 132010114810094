<vw-header-padding-safe-area></vw-header-padding-safe-area>
<div class="p-4 pt-16 relative w-full h-full">
  <div class="flex justify-between items-center">
    <h2 class="text-[#062825] text-[32px] font-semibold pb-3">{{ "garden.title" | translate}}</h2>
    <div class="flex items-baseline justify-between pb-3 relative cursor-pointer z-[999]" id="dropdownIcon">
      <vw-plus-icon class="text-white p-2 rounded-full bg-primary z-10"
        (click)="showAddDropdown = !showAddDropdown"></vw-plus-icon>

      <div [ngClass]="{
        'opacity-100 pointer-events-auto visible translate-y-0': showAddDropdown,
        'opacity-0 pointer-events-none invisible -translate-y-2': !showAddDropdown
      }"
        class="absolute right-0 mt-12 min-w-max bg-white border invisible rounded shadow-lg py-1 hover:text-white transition-all duration-300"
        id="dropdown">
        <div class="flex justify-start px-4 py-2 gap-2" (click)="onAddPlant()">
          <vw-plus-icon class="text-primary-500"></vw-plus-icon>
          <span class="text-primary-500 font-semibold text-base z-[9]">
            {{"garden.add.cultivation" | translate}}</span>
        </div>
        <div class="flex justify-start px-4 py-2 gap-2" (click)="onAddSeed()">
          <vw-plus-icon class="text-primary-500"></vw-plus-icon>
          <span class="text-primary-500 font-semibold text-base z-[9]">
            {{"garden.add.seed" | translate}}</span>
        </div>
      </div>
    </div>

  </div>

  <vw-tab [tabs]="tabData" (tabSelected)="onTabSelected($event)" [activeTab]="activeTab" [isLoading]="isLoading">
    <div class="tab-content">
      <!-- Active tab content will be loaded here -->
      <router-outlet></router-outlet>
    </div>
  </vw-tab>
</div>