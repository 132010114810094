import { Component } from '@angular/core';

@Component({
  selector: 'vw-earth-icon',
  standalone: true,
  imports: [],
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.54 15H17C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17V21.54M7 3.34009V5.00009C7 5.79574 7.31607 6.5588 7.87868 7.12141C8.44129 7.68402 9.20435 8.00009 10 8.00009C10.5304 8.00009 11.0391 8.2108 11.4142 8.58587C11.7893 8.96095 12 9.46965 12 10.0001C12 11.1001 12.9 12.0001 14 12.0001C14.5304 12.0001 15.0391 11.7894 15.4142 11.4143C15.7893 11.0392 16 10.5305 16 10.0001C16 8.90009 16.9 8.00009 18 8.00009H21.17M11 21.95V18C11 17.4696 10.7893 16.9609 10.4143 16.5858C10.0392 16.2107 9.53048 16 9.00005 16C8.46962 16 7.96091 15.7893 7.58584 15.4142C7.21076 15.0391 7.00005 14.5304 7.00005 14V13C7.00005 12.4696 6.78934 11.9609 6.41426 11.5858C6.03919 11.2107 5.53048 11 5.00005 11H2.05005M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
})
export class EarthIconComponent {

}
