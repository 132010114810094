import {Injectable, OnDestroy} from '@angular/core';

import {Router} from '@angular/router';
import {WebViewAction} from '../../enums/webview-action';
import {Subject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class WebViewBridgeService implements OnDestroy {
	permissionAskedSubject: Subject<any> = new Subject<any>();
	constructor(private router: Router) {}

	private handleMessageCallback: (event: any) => void;

	listen() {
		this.handleMessageCallback = this.handleMessage.bind(this);
		window.addEventListener('message', this.handleMessageCallback, false);
	}

	/**
	 * @param event postMessage event
	 * @private
	 * @description Handle message from any other postMessage candidate (Mobile App, Webview, etc)
	 */
	private handleMessage(event: any) {
		if (event?.data) {
			const message: { type: WebViewAction; payload: any } =
				event.data instanceof String ? JSON.parse(event.data) : event.data;
			switch (message.type) {
				case WebViewAction.navigate:
					if (
						message?.payload?.pathName &&
						typeof message?.payload?.pathName === 'string'
					) {
						this.router.navigate([message.payload.pathName]);
					}
					break;
				case WebViewAction.permissionAsked:
					this.permissionAskedSubject.next(message.payload);
					break;
				default:
					console.warn('Unknown message type', message.type);
			}
		}
	}
	/**
	 *
	 * @param message Message to be sent at the other postMessage candidate (Mobile App, Webview, etc)
	 * @description Send message to any other postMessage candidate (Mobile App, Webview, etc)
	 */
	postMessageToChannel(channelName: string, message: any): void {
		const targetChannel = (window as any)[channelName];
		console.log('Target channel:', targetChannel);
		if (targetChannel && typeof targetChannel.postMessage === 'function') {
		  const stringified = typeof message === 'string' ? message : JSON.stringify(message);
		  console.log(`Sending to channel ${channelName}:`, stringified);
		  targetChannel.postMessage(stringified);
		} else {
		  console.warn(`Channel "${channelName}" not available or does not support postMessage`);
		}
	  }

	ngOnDestroy(): void {
		if (this.handleMessageCallback) {
			window.removeEventListener('message', this.handleMessageCallback, false);
		}
	}
}
