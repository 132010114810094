import { Component, Input, TemplateRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { SharedModule } from '../../../ui-components/shared.module';

@Component({
  selector: 'vw-app-menu-item',
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    SharedModule,
    NgIf,
  ],
  templateUrl: './app-menu-item.component.html',
  styleUrl: './app-menu-item.component.scss'
})
export class AppMenuItemComponent {
  @Input() currentScreen;
  @Input() screenName;
  @Input() iconUrl;
  @Input() label;
  @Input() iconTemplate: TemplateRef<any> | null;
  @Input() disabled: boolean = false;
}
