import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../ui-components/shared.module';
import { Directive, ElementRef, HostListener } from '@angular/core';



@Component({
  selector: 'vw-ai-chatbot-input-message',
  standalone: true,
  imports: [
    FormsModule,
    SharedModule,
    TranslateModule
  ],
  templateUrl: './ai-chatbot-input-message.component.html',
  styleUrl: './ai-chatbot-input-message.component.scss'
})
export class AiChatbotInputMessageComponent {
  @Output() messageSubmitted = new EventEmitter<string>();
  @Output() inputFocus = new EventEmitter<void>();
  @Output() inputBlur = new EventEmitter<void>();

  message: string = '';

  resetMessage() {
    this.message = '';
  }

  sendMessage() {
    if (this.message.length > 0) {
      this.messageSubmitted.emit(this.message);
      this.resetMessage();
    }
  }

  onEnter() {
    this.sendMessage();
  }

  onSendButtonClick() {
    this.sendMessage();
  }

  onInputFocus() {
    this.inputFocus.emit();
  }

  onInputBlur() {
    this.inputBlur.emit();
  }
}
