<div
  class="w-[64px] h-16 bg-white rounded-2xl border-2 border-orange-500 z-[999] m-auto flex justify-center items-center shadow-xl">
  <div class="flex justify-center items-center">
    <div class="logo-container" tabindex="1" (contextmenu)="onContextMenu($event)" (touchstart)="prepare()"
      (touchend)="jump()" (animationend)="onAnimationEnd($event)">
      <div [ngClass]="{'prepare-jump': isPreparing, 'jumping': isJumping}">
        <svg width="556" height="823" viewBox="0 0 556 823" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group 2">
            <g id="Clip path group">
              <mask id="mask0_3_146" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="233" width="510"
                height="228">
                <g id="edecdc0a1b">
                  <path id="Vector" d="M46 233.667H555.333V460.333H46V233.667Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask0_3_146)">
                <g id="Group">
                  <g id="Clip path group_2">
                    <mask id="mask1_3_146" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="234"
                      width="509" height="226">
                      <g id="b751b3fc0b">
                        <path id="Vector_2"
                          d="M278.021 459.318L204.104 331.667H102.771L46.3645 234.849H510.323L554.703 331.667H351.937L278.021 459.318Z"
                          fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask1_3_146)">
                      <g id="Group_2">
                        <path id="Vector_3" d="M46.3645 234.849V459.318H554.703V234.849H46.3645Z"
                          fill="url(#paint0_linear_3_146)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Clip path group_3">
              <mask id="mask2_3_146" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="223" y="331" width="333"
                height="492">
                <g id="f42a47ad03">
                  <path id="Vector_4" d="M223.333 331H555.333V823H223.333V331Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask2_3_146)">
                <g id="Group_3">
                  <g id="Clip path group_4">
                    <mask id="mask3_3_146" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="223" y="331"
                      width="332" height="491">
                      <g id="4bac7898d3">
                        <path id="Vector_5"
                          d="M278.021 821.849L223.786 726.042L453.276 331.667H554.703L278.021 821.849Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask3_3_146)">
                      <g id="Group_4">
                        <path id="Vector_6"
                          d="M773.495 466.307L314.677 183.964L4.99487 687.208L463.807 969.552L773.495 466.307Z"
                          fill="url(#paint1_linear_3_146)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Clip path group_5">
              <mask id="mask4_3_146" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="233" width="278"
                height="494">
                <g id="a5a3ecd58b">
                  <path id="Vector_7" d="M0.666626 233.667H278V727H0.666626V233.667Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask4_3_146)">
                <g id="Group_5">
                  <g id="Clip path group_6">
                    <mask id="mask5_3_146" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="234"
                      width="278" height="493">
                      <g id="9fb86f6101">
                        <path id="Vector_8"
                          d="M223.786 726.042L0.973877 332.453L45.677 234.849H46.3645L277.698 633.323L223.74 726.042H223.786Z"
                          fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask5_3_146)">
                      <g id="Group_6">
                        <path id="Vector_9"
                          d="M78.3384 850.235L498.146 588.719L200.333 110.656L-219.469 372.177L78.3384 850.235Z"
                          fill="url(#paint2_linear_3_146)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Mask group">
              <mask id="mask6_3_146" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="46" y="0" width="465"
                height="192">
                <path id="Rectangle 1" d="M46 0H511V192H278.5H46V0Z" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask6_3_146)">
                <g id="Group 1">
                  <g id="Mask group_2">
                    <mask id="mask7_3_146" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="59" y="62" width="185"
                      height="150">
                      <path id="left-leaf-mask" d="M159.375 211.396L89.0833 62.594H174.75L243.75 211.396H159.375Z"
                        fill="black" />
                    </mask>
                    <g mask="url(#mask7_3_146)">
                      <path id="left-leaf" d="M324.75 62.594H9.08325V211.396H324.75V62.594Z"
                        fill="url(#paint3_linear_3_146)" />
                    </g>
                  </g>
                  <g id="Mask group_3">
                    <mask id="mask8_3_146" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="310" y="62" width="186"
                      height="150">
                      <path id="right-leaf-mask"
                        d="M310.25 211.396L380.25 62.594H465.917L430.271 136.995L394.625 211.396H310.25Z"
                        fill="black" />
                    </mask>
                    <g mask="url(#mask8_3_146)">
                      <path id="Vector_10" d="M485.917 62.594H300.25V211.396H485.917V62.594Z"
                        fill="url(#paint4_linear_3_146)" />
                    </g>
                  </g>
                  <path id="center-leaf-mask" d="M320.604 0.197998H234.75V211.438H320.604V0.197998Z"
                    fill="url(#paint5_linear_3_146)" />
                </g>
              </g>
            </g>
          </g>
          <!-- <g id="left-eye-group">
            <circle class="eye" cx="160" cy="375" r="60" fill="white" opacity="0.9" />
            <circle class="highlight" cx="183" cy="363" r="24" fill="#333" />
          </g>
          <g id="right-eye-group">
            <circle class="eye" cx="400" cy="380" r="60" fill="white" opacity="0.9" />
            <circle class="highlight" cx="413" cy="363" r="24" fill="#333" />
          </g>
          <g id="mouth" transform="translate(0, -15)">
            <path id="left-tooth" d="M230 551.75 H280 V607.5 Q255 651.75 230 607.5 Z" fill="#E53935" opacity="0.9" />
            <path id="right-tooth" d="M284 551.75 H334 V622.5 Q309 666.75 284 622.5 Z" fill="#E53935" opacity="0.9" />
            <path id="mustache" d="M180 519 Q227.5 615 285 519 Q342.5 615 390 519" stroke="white" stroke-width="35"
              stroke-linecap="round" stroke-linejoin="round" fill="none" opacity="0.9" />
          </g> -->
          <defs>
            <linearGradient id="paint0_linear_3_146" x1="46.366" y1="347.088" x2="554.703" y2="347.088"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F05E16" />
              <stop offset="0.015625" stop-color="#F05F16" />
              <stop offset="0.03125" stop-color="#F06016" />
              <stop offset="0.046875" stop-color="#F06116" />
              <stop offset="0.0625" stop-color="#F06216" />
              <stop offset="0.078125" stop-color="#F16316" />
              <stop offset="0.09375" stop-color="#F16416" />
              <stop offset="0.109375" stop-color="#F16416" />
              <stop offset="0.125" stop-color="#F16517" />
              <stop offset="0.140625" stop-color="#F16617" />
              <stop offset="0.15625" stop-color="#F16717" />
              <stop offset="0.171875" stop-color="#F26817" />
              <stop offset="0.1875" stop-color="#F26917" />
              <stop offset="0.203125" stop-color="#F26A17" />
              <stop offset="0.21875" stop-color="#F26B17" />
              <stop offset="0.234375" stop-color="#F26C17" />
              <stop offset="0.25" stop-color="#F26D17" />
              <stop offset="0.265625" stop-color="#F36E17" />
              <stop offset="0.28125" stop-color="#F36F17" />
              <stop offset="0.296875" stop-color="#F37017" />
              <stop offset="0.3125" stop-color="#F37117" />
              <stop offset="0.328125" stop-color="#F37218" />
              <stop offset="0.34375" stop-color="#F37318" />
              <stop offset="0.359375" stop-color="#F37418" />
              <stop offset="0.375" stop-color="#F47518" />
              <stop offset="0.390625" stop-color="#F47618" />
              <stop offset="0.40625" stop-color="#F47718" />
              <stop offset="0.421875" stop-color="#F47818" />
              <stop offset="0.4375" stop-color="#F47918" />
              <stop offset="0.453125" stop-color="#F47A18" />
              <stop offset="0.46875" stop-color="#F57B18" />
              <stop offset="0.484375" stop-color="#F57C18" />
              <stop offset="0.5" stop-color="#F57D18" />
              <stop offset="0.515625" stop-color="#F57E19" />
              <stop offset="0.53125" stop-color="#F57F19" />
              <stop offset="0.546875" stop-color="#F58019" />
              <stop offset="0.5625" stop-color="#F58119" />
              <stop offset="0.578125" stop-color="#F68219" />
              <stop offset="0.59375" stop-color="#F68319" />
              <stop offset="0.609375" stop-color="#F68419" />
              <stop offset="0.625" stop-color="#F68519" />
              <stop offset="0.640625" stop-color="#F68619" />
              <stop offset="0.65625" stop-color="#F68719" />
              <stop offset="0.671875" stop-color="#F78819" />
              <stop offset="0.6875" stop-color="#F78919" />
              <stop offset="0.703125" stop-color="#F78A19" />
              <stop offset="0.71875" stop-color="#F78B1A" />
              <stop offset="0.734375" stop-color="#F78C1A" />
              <stop offset="0.75" stop-color="#F78D1A" />
              <stop offset="0.765625" stop-color="#F78E1A" />
              <stop offset="0.78125" stop-color="#F88F1A" />
              <stop offset="0.796875" stop-color="#F8901A" />
              <stop offset="0.8125" stop-color="#F8911A" />
              <stop offset="0.828125" stop-color="#F8921A" />
              <stop offset="0.84375" stop-color="#F8931A" />
              <stop offset="0.859375" stop-color="#F8941A" />
              <stop offset="0.875" stop-color="#F9951A" />
              <stop offset="0.890625" stop-color="#F9961A" />
              <stop offset="0.90625" stop-color="#F9971B" />
              <stop offset="0.921875" stop-color="#F9981B" />
              <stop offset="0.9375" stop-color="#F9991B" />
              <stop offset="0.953125" stop-color="#F99A1B" />
              <stop offset="0.96875" stop-color="#FA9B1B" />
              <stop offset="0.984375" stop-color="#FA9C1B" />
              <stop offset="1" stop-color="#FA9D1B" />
            </linearGradient>
            <linearGradient id="paint1_linear_3_146" x1="526.947" y1="314.589" x2="217.263" y2="817.836"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F16216" />
              <stop offset="0.015625" stop-color="#F16216" />
              <stop offset="0.03125" stop-color="#F16316" />
              <stop offset="0.046875" stop-color="#F16416" />
              <stop offset="0.0625" stop-color="#F16516" />
              <stop offset="0.078125" stop-color="#F16617" />
              <stop offset="0.09375" stop-color="#F16717" />
              <stop offset="0.109375" stop-color="#F26817" />
              <stop offset="0.125" stop-color="#F26917" />
              <stop offset="0.140625" stop-color="#F26A17" />
              <stop offset="0.15625" stop-color="#F26B17" />
              <stop offset="0.171875" stop-color="#F26C17" />
              <stop offset="0.1875" stop-color="#F26D17" />
              <stop offset="0.203125" stop-color="#F26E17" />
              <stop offset="0.21875" stop-color="#F36F17" />
              <stop offset="0.234375" stop-color="#F36F17" />
              <stop offset="0.25" stop-color="#F37017" />
              <stop offset="0.265625" stop-color="#F37117" />
              <stop offset="0.28125" stop-color="#F37218" />
              <stop offset="0.296875" stop-color="#F37318" />
              <stop offset="0.3125" stop-color="#F37418" />
              <stop offset="0.328125" stop-color="#F47518" />
              <stop offset="0.34375" stop-color="#F47618" />
              <stop offset="0.359375" stop-color="#F47718" />
              <stop offset="0.375" stop-color="#F47818" />
              <stop offset="0.390625" stop-color="#F47918" />
              <stop offset="0.40625" stop-color="#F47A18" />
              <stop offset="0.421875" stop-color="#F47B18" />
              <stop offset="0.4375" stop-color="#F57C18" />
              <stop offset="0.453125" stop-color="#F57D18" />
              <stop offset="0.46875" stop-color="#F57E18" />
              <stop offset="0.484375" stop-color="#F57E19" />
              <stop offset="0.5" stop-color="#F57F19" />
              <stop offset="0.515625" stop-color="#F58019" />
              <stop offset="0.53125" stop-color="#F68119" />
              <stop offset="0.546875" stop-color="#F68219" />
              <stop offset="0.5625" stop-color="#F68319" />
              <stop offset="0.578125" stop-color="#F68419" />
              <stop offset="0.59375" stop-color="#F68519" />
              <stop offset="0.609375" stop-color="#F68619" />
              <stop offset="0.625" stop-color="#F68719" />
              <stop offset="0.640625" stop-color="#F78819" />
              <stop offset="0.65625" stop-color="#F78919" />
              <stop offset="0.671875" stop-color="#F78A19" />
              <stop offset="0.6875" stop-color="#F78B1A" />
              <stop offset="0.703125" stop-color="#F78C1A" />
              <stop offset="0.71875" stop-color="#F78D1A" />
              <stop offset="0.734375" stop-color="#F78E1A" />
              <stop offset="0.75" stop-color="#F88E1A" />
              <stop offset="0.765625" stop-color="#F88F1A" />
              <stop offset="0.78125" stop-color="#F8901A" />
              <stop offset="0.796875" stop-color="#F8911A" />
              <stop offset="0.8125" stop-color="#F8921A" />
              <stop offset="0.828125" stop-color="#F8931A" />
              <stop offset="0.84375" stop-color="#F8941A" />
              <stop offset="0.859375" stop-color="#F9951A" />
              <stop offset="0.875" stop-color="#F9961A" />
              <stop offset="0.890625" stop-color="#F9971B" />
              <stop offset="0.90625" stop-color="#F9981B" />
              <stop offset="0.921875" stop-color="#F9991B" />
              <stop offset="0.9375" stop-color="#F99A1B" />
              <stop offset="0.953125" stop-color="#FA9B1B" />
              <stop offset="0.96875" stop-color="#FA9C1B" />
              <stop offset="1" stop-color="#FA9D1B" />
            </linearGradient>
            <linearGradient id="paint2_linear_3_146" x1="285.031" y1="721.477" x2="-12.7776" y2="243.417"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F05E16" />
              <stop offset="0.0625" stop-color="#F05E16" />
              <stop offset="0.0703125" stop-color="#F05F16" />
              <stop offset="0.078125" stop-color="#F05F16" />
              <stop offset="0.0859375" stop-color="#F06016" />
              <stop offset="0.09375" stop-color="#F06116" />
              <stop offset="0.101562" stop-color="#F06116" />
              <stop offset="0.109375" stop-color="#F16216" />
              <stop offset="0.117188" stop-color="#F16216" />
              <stop offset="0.125" stop-color="#F16316" />
              <stop offset="0.132812" stop-color="#F16316" />
              <stop offset="0.140625" stop-color="#F16416" />
              <stop offset="0.148438" stop-color="#F16516" />
              <stop offset="0.15625" stop-color="#F16517" />
              <stop offset="0.164062" stop-color="#F16617" />
              <stop offset="0.171875" stop-color="#F16617" />
              <stop offset="0.179688" stop-color="#F16717" />
              <stop offset="0.1875" stop-color="#F16717" />
              <stop offset="0.195313" stop-color="#F26817" />
              <stop offset="0.203125" stop-color="#F26917" />
              <stop offset="0.210938" stop-color="#F26917" />
              <stop offset="0.21875" stop-color="#F26A17" />
              <stop offset="0.226562" stop-color="#F26A17" />
              <stop offset="0.234375" stop-color="#F26B17" />
              <stop offset="0.242188" stop-color="#F26B17" />
              <stop offset="0.25" stop-color="#F26C17" />
              <stop offset="0.257812" stop-color="#F26D17" />
              <stop offset="0.265625" stop-color="#F26D17" />
              <stop offset="0.273437" stop-color="#F26E17" />
              <stop offset="0.28125" stop-color="#F36E17" />
              <stop offset="0.289062" stop-color="#F36F17" />
              <stop offset="0.296875" stop-color="#F36F17" />
              <stop offset="0.304688" stop-color="#F37017" />
              <stop offset="0.3125" stop-color="#F37117" />
              <stop offset="0.320312" stop-color="#F37117" />
              <stop offset="0.328125" stop-color="#F37218" />
              <stop offset="0.335938" stop-color="#F37218" />
              <stop offset="0.34375" stop-color="#F37318" />
              <stop offset="0.351562" stop-color="#F37318" />
              <stop offset="0.359375" stop-color="#F37418" />
              <stop offset="0.367188" stop-color="#F47518" />
              <stop offset="0.375" stop-color="#F47518" />
              <stop offset="0.382813" stop-color="#F47618" />
              <stop offset="0.390625" stop-color="#F47618" />
              <stop offset="0.398438" stop-color="#F47718" />
              <stop offset="0.40625" stop-color="#F47718" />
              <stop offset="0.414063" stop-color="#F47818" />
              <stop offset="0.421875" stop-color="#F47918" />
              <stop offset="0.429688" stop-color="#F47918" />
              <stop offset="0.4375" stop-color="#F47A18" />
              <stop offset="0.445312" stop-color="#F47A18" />
              <stop offset="0.453125" stop-color="#F47B18" />
              <stop offset="0.460937" stop-color="#F57B18" />
              <stop offset="0.46875" stop-color="#F57C18" />
              <stop offset="0.476563" stop-color="#F57D18" />
              <stop offset="0.484375" stop-color="#F57D18" />
              <stop offset="0.492187" stop-color="#F57E18" />
              <stop offset="0.5" stop-color="#F57E19" />
              <stop offset="0.507812" stop-color="#F57F19" />
              <stop offset="0.515625" stop-color="#F57F19" />
              <stop offset="0.523438" stop-color="#F58019" />
              <stop offset="0.53125" stop-color="#F58119" />
              <stop offset="0.539062" stop-color="#F58119" />
              <stop offset="0.546875" stop-color="#F68219" />
              <stop offset="0.554688" stop-color="#F68219" />
              <stop offset="0.5625" stop-color="#F68319" />
              <stop offset="0.570312" stop-color="#F68319" />
              <stop offset="0.578125" stop-color="#F68419" />
              <stop offset="0.585938" stop-color="#F68519" />
              <stop offset="0.59375" stop-color="#F68519" />
              <stop offset="0.601562" stop-color="#F68619" />
              <stop offset="0.609375" stop-color="#F68619" />
              <stop offset="0.617188" stop-color="#F68719" />
              <stop offset="0.625" stop-color="#F68719" />
              <stop offset="0.632812" stop-color="#F78819" />
              <stop offset="0.640625" stop-color="#F78919" />
              <stop offset="0.648438" stop-color="#F78919" />
              <stop offset="0.65625" stop-color="#F78A19" />
              <stop offset="0.664062" stop-color="#F78A1A" />
              <stop offset="0.671875" stop-color="#F78B1A" />
              <stop offset="0.679688" stop-color="#F78B1A" />
              <stop offset="0.6875" stop-color="#F78C1A" />
              <stop offset="0.695312" stop-color="#F78D1A" />
              <stop offset="0.703125" stop-color="#F78D1A" />
              <stop offset="0.710938" stop-color="#F78E1A" />
              <stop offset="0.71875" stop-color="#F88E1A" />
              <stop offset="0.726562" stop-color="#F88F1A" />
              <stop offset="0.734375" stop-color="#F88F1A" />
              <stop offset="0.742188" stop-color="#F8901A" />
              <stop offset="0.75" stop-color="#F8911A" />
              <stop offset="0.757812" stop-color="#F8911A" />
              <stop offset="0.765625" stop-color="#F8921A" />
              <stop offset="0.773438" stop-color="#F8921A" />
              <stop offset="0.78125" stop-color="#F8931A" />
              <stop offset="0.789062" stop-color="#F8931A" />
              <stop offset="0.796875" stop-color="#F8941A" />
              <stop offset="0.804688" stop-color="#F9951A" />
              <stop offset="0.8125" stop-color="#F9951A" />
              <stop offset="0.820312" stop-color="#F9961A" />
              <stop offset="0.828125" stop-color="#F9961A" />
              <stop offset="0.835938" stop-color="#F9971B" />
              <stop offset="0.84375" stop-color="#F9971B" />
              <stop offset="0.851563" stop-color="#F9981B" />
              <stop offset="0.859375" stop-color="#F9991B" />
              <stop offset="0.867188" stop-color="#F9991B" />
              <stop offset="0.875" stop-color="#F99A1B" />
              <stop offset="0.882813" stop-color="#F99A1B" />
              <stop offset="0.890625" stop-color="#FA9B1B" />
              <stop offset="0.898438" stop-color="#FA9B1B" />
              <stop offset="0.90625" stop-color="#FA9C1B" />
              <stop offset="0.9375" stop-color="#FA9D1B" />
              <stop offset="1" stop-color="#FA9D1B" />
            </linearGradient>
            <linearGradient id="paint3_linear_3_146" x1="161.932" y1="62.5933" x2="161.932" y2="191.394"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#317756" />
              <stop offset="0.015625" stop-color="#317756" />
              <stop offset="0.03125" stop-color="#317756" />
              <stop offset="0.046875" stop-color="#317756" />
              <stop offset="0.0625" stop-color="#317756" />
              <stop offset="0.078125" stop-color="#317756" />
              <stop offset="0.09375" stop-color="#317756" />
              <stop offset="0.109375" stop-color="#317756" />
              <stop offset="0.125" stop-color="#317756" />
              <stop offset="0.140625" stop-color="#317756" />
              <stop offset="0.15625" stop-color="#317756" />
              <stop offset="0.171875" stop-color="#317756" />
              <stop offset="0.1875" stop-color="#317756" />
              <stop offset="0.203125" stop-color="#317756" />
              <stop offset="0.21875" stop-color="#317756" />
              <stop offset="0.234375" stop-color="#317756" />
              <stop offset="0.25" stop-color="#317756" />
              <stop offset="0.265625" stop-color="#317756" />
              <stop offset="0.28125" stop-color="#317756" />
              <stop offset="0.296875" stop-color="#317756" />
              <stop offset="0.3125" stop-color="#317756" />
              <stop offset="0.328125" stop-color="#317756" />
              <stop offset="0.34375" stop-color="#317756" />
              <stop offset="0.359375" stop-color="#317756" />
              <stop offset="0.375" stop-color="#317756" />
              <stop offset="0.390625" stop-color="#317756" />
              <stop offset="0.40625" stop-color="#317756" />
              <stop offset="0.421875" stop-color="#317756" />
              <stop offset="0.4375" stop-color="#317756" />
              <stop offset="0.453125" stop-color="#317756" />
              <stop offset="0.46875" stop-color="#317756" />
              <stop offset="0.484375" stop-color="#317756" />
              <stop offset="0.5" stop-color="#317756" />
              <stop offset="0.515625" stop-color="#317756" />
              <stop offset="0.53125" stop-color="#317756" />
              <stop offset="0.5625" stop-color="#317756" />
              <stop offset="0.625" stop-color="#317756" />
              <stop offset="0.75" stop-color="#317756" />
              <stop offset="1" stop-color="#317756" />
            </linearGradient>
            <linearGradient id="paint4_linear_3_146" x1="393.068" y1="62.5933" x2="393.068" y2="191.394"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#317756" />
              <stop offset="0.015625" stop-color="#317756" />
              <stop offset="0.03125" stop-color="#317756" />
              <stop offset="0.046875" stop-color="#317756" />
              <stop offset="0.0625" stop-color="#317756" />
              <stop offset="0.078125" stop-color="#317756" />
              <stop offset="0.09375" stop-color="#317756" />
              <stop offset="0.109375" stop-color="#317756" />
              <stop offset="0.125" stop-color="#317756" />
              <stop offset="0.140625" stop-color="#317756" />
              <stop offset="0.15625" stop-color="#317756" />
              <stop offset="0.171875" stop-color="#317756" />
              <stop offset="0.1875" stop-color="#317756" />
              <stop offset="0.203125" stop-color="#317756" />
              <stop offset="0.21875" stop-color="#317756" />
              <stop offset="0.234375" stop-color="#317756" />
              <stop offset="0.25" stop-color="#317756" />
              <stop offset="0.265625" stop-color="#317756" />
              <stop offset="0.28125" stop-color="#317756" />
              <stop offset="0.296875" stop-color="#317756" />
              <stop offset="0.3125" stop-color="#317756" />
              <stop offset="0.328125" stop-color="#317756" />
              <stop offset="0.34375" stop-color="#317756" />
              <stop offset="0.359375" stop-color="#317756" />
              <stop offset="0.375" stop-color="#317756" />
              <stop offset="0.390625" stop-color="#317756" />
              <stop offset="0.40625" stop-color="#317756" />
              <stop offset="0.421875" stop-color="#317756" />
              <stop offset="0.4375" stop-color="#317756" />
              <stop offset="0.453125" stop-color="#317756" />
              <stop offset="0.46875" stop-color="#317756" />
              <stop offset="0.484375" stop-color="#317756" />
              <stop offset="0.5" stop-color="#317756" />
              <stop offset="0.515625" stop-color="#317756" />
              <stop offset="0.53125" stop-color="#317756" />
              <stop offset="0.5625" stop-color="#317756" />
              <stop offset="0.625" stop-color="#317756" />
              <stop offset="0.75" stop-color="#317756" />
              <stop offset="1" stop-color="#317756" />
            </linearGradient>
            <linearGradient id="paint5_linear_3_146" x1="277.7" y1="0.197408" x2="277.7" y2="191.44"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#317756" />
              <stop offset="0.0078125" stop-color="#317756" />
              <stop offset="0.015625" stop-color="#317756" />
              <stop offset="0.0234375" stop-color="#317756" />
              <stop offset="0.03125" stop-color="#317756" />
              <stop offset="0.0390625" stop-color="#317756" />
              <stop offset="0.046875" stop-color="#317756" />
              <stop offset="0.0546875" stop-color="#317756" />
              <stop offset="0.0625" stop-color="#317756" />
              <stop offset="0.0703125" stop-color="#317756" />
              <stop offset="0.078125" stop-color="#317756" />
              <stop offset="0.0859375" stop-color="#317756" />
              <stop offset="0.09375" stop-color="#317756" />
              <stop offset="0.101563" stop-color="#317756" />
              <stop offset="0.109375" stop-color="#317756" />
              <stop offset="0.117187" stop-color="#317756" />
              <stop offset="0.125" stop-color="#317756" />
              <stop offset="0.132812" stop-color="#317756" />
              <stop offset="0.140625" stop-color="#317756" />
              <stop offset="0.148438" stop-color="#317756" />
              <stop offset="0.15625" stop-color="#317756" />
              <stop offset="0.164062" stop-color="#317756" />
              <stop offset="0.171875" stop-color="#317756" />
              <stop offset="0.179688" stop-color="#317756" />
              <stop offset="0.1875" stop-color="#317756" />
              <stop offset="0.195313" stop-color="#317756" />
              <stop offset="0.203125" stop-color="#317756" />
              <stop offset="0.210937" stop-color="#317756" />
              <stop offset="0.21875" stop-color="#317756" />
              <stop offset="0.226562" stop-color="#317756" />
              <stop offset="0.234375" stop-color="#317756" />
              <stop offset="0.242188" stop-color="#317756" />
              <stop offset="0.25" stop-color="#317756" />
              <stop offset="0.257812" stop-color="#317756" />
              <stop offset="0.265625" stop-color="#317756" />
              <stop offset="0.273438" stop-color="#317756" />
              <stop offset="0.28125" stop-color="#317756" />
              <stop offset="0.289063" stop-color="#317756" />
              <stop offset="0.296875" stop-color="#317756" />
              <stop offset="0.304688" stop-color="#317756" />
              <stop offset="0.3125" stop-color="#317756" />
              <stop offset="0.320312" stop-color="#317756" />
              <stop offset="0.328125" stop-color="#317756" />
              <stop offset="0.335937" stop-color="#317756" />
              <stop offset="0.34375" stop-color="#317756" />
              <stop offset="0.351562" stop-color="#317756" />
              <stop offset="0.359375" stop-color="#317756" />
              <stop offset="0.367188" stop-color="#317756" />
              <stop offset="0.375" stop-color="#317756" />
              <stop offset="0.382813" stop-color="#317756" />
              <stop offset="0.390625" stop-color="#317756" />
              <stop offset="0.398438" stop-color="#317756" />
              <stop offset="0.40625" stop-color="#317756" />
              <stop offset="0.414062" stop-color="#317756" />
              <stop offset="0.421875" stop-color="#317756" />
              <stop offset="0.429687" stop-color="#317756" />
              <stop offset="0.4375" stop-color="#317756" />
              <stop offset="0.445312" stop-color="#317756" />
              <stop offset="0.453125" stop-color="#317756" />
              <stop offset="0.460938" stop-color="#317756" />
              <stop offset="0.46875" stop-color="#317756" />
              <stop offset="0.476563" stop-color="#317756" />
              <stop offset="0.484375" stop-color="#317756" />
              <stop offset="0.492188" stop-color="#317756" />
              <stop offset="0.5" stop-color="#317756" />
              <stop offset="0.507812" stop-color="#317756" />
              <stop offset="0.515625" stop-color="#317756" />
              <stop offset="0.523438" stop-color="#317756" />
              <stop offset="0.53125" stop-color="#317756" />
              <stop offset="0.539062" stop-color="#317756" />
              <stop offset="0.546875" stop-color="#317756" />
              <stop offset="0.554688" stop-color="#317756" />
              <stop offset="0.5625" stop-color="#317756" />
              <stop offset="0.570312" stop-color="#317756" />
              <stop offset="0.578125" stop-color="#317756" />
              <stop offset="0.585938" stop-color="#317756" />
              <stop offset="0.59375" stop-color="#317756" />
              <stop offset="0.601562" stop-color="#317756" />
              <stop offset="0.609375" stop-color="#317756" />
              <stop offset="0.617188" stop-color="#317756" />
              <stop offset="0.625" stop-color="#317756" />
              <stop offset="0.632812" stop-color="#317756" />
              <stop offset="0.640625" stop-color="#317756" />
              <stop offset="0.648438" stop-color="#317756" />
              <stop offset="0.65625" stop-color="#317756" />
              <stop offset="0.664062" stop-color="#317756" />
              <stop offset="0.671875" stop-color="#317756" />
              <stop offset="0.6875" stop-color="#317756" />
              <stop offset="0.75" stop-color="#317756" />
              <stop offset="1" stop-color="#317756" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</div>