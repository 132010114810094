import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../../../../../@commons/ui-components/shared.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocationHomeIconComponent } from '../../../../../../@icons/location-home-icon.component';
import { ChevronRightIconComponent } from '../../../../../../@icons/chevron-right.component';


@Component({
  selector: 'vw-user-adress-location-card',
  standalone: true,
  imports: [SharedModule, TranslateModule, LocationHomeIconComponent, CommonModule, ChevronRightIconComponent],
  templateUrl: './user-adress-location-card.component.html',
  styleUrl: './user-adress-location-card.component.scss'
})
export class UserAdressLocationCardComponent {
  @Input() location: any;
  @Output() editLocation = new EventEmitter<string>();
  clickedLocation = false;

  onClickLocation() {
    this.clickedLocation = true;
    this.editLocation.emit(this.location.uid);
  }
}
