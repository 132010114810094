import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../@commons/ui-components/shared.module';
import { ArticleComponent } from "../../@commons/@components/article/article.component";
import {
  TaskListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/task-list.request';
import { StorageService } from '../../@commons/@services/front/storage.service';
import {
  CultivationHierarchyApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import {
  CalendarCultivationHierarchyRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/cultivation/calendar-cultivation-hierarchy-list.request';
import moment from 'moment';
import {
  CalendarCultivationHierarchyListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';
import { UserHierarchiesComponent } from '../../garden/user-hierarchies/user-hierarchies.component';
import { CalendarHierarchiesComponent } from '../@components/calendar-hierarchies/calendar-hierarchies.component';
import {
  CultivationPeriodType
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/cultivation-period-type.enum';
import { ClimateType } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/climate-type.enum';
import {
  CultivationGuideToastComponent
} from '../../garden/@toasts/cultivation-guide-toast/cultivation-guide-toast.component';
import { PlantApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/plant/plant-api';
import {
  CalendarCultivationListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';
import {
  CultivationVariantListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/cultivation-variant-list.response';
import { shake } from '../../@commons/animations/animations';

@Component({
  selector: 'vw-calendar-seedling', // Component selector
  standalone: true, // Indicates this is a standalone component
  imports: [CommonModule, SharedModule, TranslateModule, ArticleComponent, UserHierarchiesComponent, CalendarHierarchiesComponent, CultivationGuideToastComponent], // Modules required by this component
  templateUrl: './calendar-seedling.component.html', // HTML template for the component
  styleUrl: './calendar-seedling.component.scss', // Stylesheet for the component
  providers: [{
    provide: CultivationHierarchyApi,
    useClass: CultivationHierarchyApi
  }, {
    provide: PlantApi,
    useClass: PlantApi
  }]
})
export class CalendarSeedlingComponent implements OnInit {


  currentMonthCalendarItems: CalendarCultivationHierarchyListResponse[] = [];
  isLoading: boolean = false;
  isShowingGuide: boolean = false;
  selectedCultivation: CalendarCultivationListResponse;
  selectedVariant: CultivationVariantListResponse;

  constructor(private cultivationHierarchyApi: CultivationHierarchyApi,
    private storageService: StorageService) { }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Subscribes to tasks and advices data from AgendaService.
   */
  async ngOnInit() {
    const taskFilters: TaskListRequest = new TaskListRequest();
    await this.loadCalendar();
  }

  async loadCalendar() {
    this.isLoading = true;
    const calendarRequest: CalendarCultivationHierarchyRequest = new CalendarCultivationHierarchyRequest();
    calendarRequest.from = moment().startOf('month').format('YYYY-MM-DD');
    calendarRequest.to = moment().endOf('month').format('YYYY-MM-DD');
    calendarRequest.cultivationPeriodTypes = [CultivationPeriodType.SEEDLING];
    calendarRequest.addressUid = this.storageService.getItem('address-uid');
    this.currentMonthCalendarItems = await this.cultivationHierarchyApi.calendar(calendarRequest, this.storageService.getCurrentLanguage());
    this.isLoading = false;
  }


  onCultivationSelected($event: CalendarCultivationListResponse) {
    console.log('test', $event.variants[0]);
    this.selectedCultivation = $event;
    this.selectedVariant = $event.variants[0];
    this.isShowingGuide = true;
  }

  protected readonly shake = shake;
}
