import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedModule } from '../@commons/ui-components/shared.module';
import { CustomFormComponent } from '../@commons/ui-components/custom-form/custom-form.component';
import { fadeIn } from '../@commons/animations/animations';
import { ProgressBarComponent } from '../@commons/@components/progress-bar/progress-bar.component';
import { TileComponent } from '../@commons/@components/tile/tile.component';
import { UserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';
import {
  UserResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/user/user.response';
import { User } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/user.entity';
import { Address } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/address.entity';
import { AddressApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/address/address-api';
import {
  AddressToUserApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/address-to-user/address-to-user-api';
import { LocationApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/location-api';
import {
  AddressToUserListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/address-to-user-list.request';
import { StorageService } from '../@commons/@services/front/storage.service';
import {
  AddressListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/address-list.request';
import { UserAddressCardComponent } from './@components/user-address-card/user-address-card.component';
import { AuthApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/auth/auth.api';
import RoutesConstants from '../@commons/constants/routes.constants';
import { LogoutIconComponent } from '../@icons/logout-icon.component';
import { EditUserToastComponent } from './@toasts/edit-user-toast/edit-user-toast.component';
import {
  HeaderPaddingSafeAreaComponent
} from '../@commons/@components/header-padding-safe-area/header-padding-safe-area.component';
import { EngineIconComponent } from '../@icons/engine-icon.component';
import { SettingsButtonComponent } from './@components/settings-button/settings-button.component';
import { ImgComponent } from '../@commons/@components/img/img.component';
import {
  ExternalFileResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/dms/external-file-response';
import { ExternalFileDto } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/external-file-dto';
import { SkeletonLoaderComponent } from '../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import { CornerLeftDownIconComponent } from '../@icons/corner-left-down-icon.component';

@Component({
  selector: 'vw-user-profile', // Component selector for app-compte
  standalone: true, // Marks this component as standalone
  imports: [SharedModule, TranslateModule, CommonModule, CustomFormComponent, ProgressBarComponent, TileComponent, UserAddressCardComponent, LogoutIconComponent, EditUserToastComponent, HeaderPaddingSafeAreaComponent, EngineIconComponent, SettingsButtonComponent, ImgComponent, SkeletonLoaderComponent, CornerLeftDownIconComponent], // Modules and components imported for use in this component
  templateUrl: './user-profile.component.html', // HTML template for the component
  styleUrl: './user-profile.component.scss', // Stylesheet for the component
  animations: [fadeIn],
  providers: [{
    provide: UserApi,
    useClass: UserApi
  }, {
    provide: AddressApi,
    useClass: AddressApi
  }, {
    provide: AddressToUserApi,
    useClass: AddressToUserApi
  },
  {
    provide: AuthApi,
    useClass: AuthApi
  }
  ]

})
export class UserProfileComponent implements OnInit {

  isBottomSheetOpen = false;
  selectedOption: string | null = null;
  accountStats: any[] = [];
  formData: any = null;
  progress: number = 50;
  editUserMode: boolean = false;
  user: User = null;
  userAddresses: Address[] = [];
  userPictureUrl: string;
  isLoading: boolean = true;

  constructor(private userApi: UserApi,
    private authApi: AuthApi,
    private addressApi: AddressApi,
    private addressToUserApi: AddressToUserApi,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute) { }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Fetches account statistics data from CompteService.
   */
  async ngOnInit() {
    await this.fetchUserInfo();
    await this.fetchUserAddresses();
    this.isLoading = false;
  }

  /**
   * Fetches mock account statistics data from the service and assigns it to accountStats.
   */
  async fetchUserInfo() {
    this.user = await this.userApi.getCurrentUser();
    if (this.user && this.user.files && this.user.files.length > 0) {
      if ((this.user.files[(this.user as any).files.length - 1] as any).cdnUrl) {
        this.userPictureUrl = (this.user.files[(this.user as any).files.length - 1] as any).cdnUrl;
      }
    }
  }

  async fetchUserAddresses() {
    const listAddressToUser = new AddressToUserListRequest();
    listAddressToUser.userUid = this.user.uid;
    const addressesToUser = await this.addressToUserApi.list(listAddressToUser, 0, 20, this.storageService.getCurrentLanguage());
    const addressesUids = addressesToUser.results.map((addressToUser) => addressToUser.addressUid);
    const listAddresses = new AddressListRequest();
    listAddresses.uids = addressesUids;
    const addressesResponse = await this.addressApi.list(listAddresses, 0, 20, this.storageService.getCurrentLanguage());
    this.userAddresses = addressesResponse.results;
  }


  logout() {
    this.authApi.logout();
    this.router.navigate([RoutesConstants.INIT]);
  }

  async onCloseEditUser($event: any) {
    await this.fetchUserInfo();
    this.editUserMode = false;
  }

  async onCloseEditAddressToast() {
    await this.fetchUserAddresses();
  }
}
