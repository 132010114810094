<!-- expandable-card.component.html -->

<vw-collapsible-card [backgroundColor]="'#051038' | HexToHsl" *ngIf="moonData && currentWeather && !isLoading"
  [cardHeaderOpenTemplate]="cardHeaderOpenTemplate" [cardHeaderCloseTemplate]="cardHeaderCloseTemplate"
  [cardBodyTemplate]="cardBodyTemplate">
  <ng-template #cardHeaderCloseTemplate>

    <div class="w-56 h-full flex items-center gap-2" [style.background-color]="backgroundColor | HexToHsl">
      <div class="w-8 h-full flex items-center justify-center shrink-0">
        <vw-moon-icon class="text-gray-800"/>
      </div>
      <p class="text-xs font-medium whitespace-normal ">
        {{ 'home.weather.moon.phases.' + moonData.moonPhase | translate }}
      </p>
    </div>
    <div class="w-full h-full flex items-center">
      <p class="text-xs pl-3 text-gray-800">{{ 'home.weather.moon.advices.' +
        moonData.moonPhase | translate }}</p>
    </div>
  </ng-template>

  <!-- Header lorsque la carte est ouverte -->
  <ng-template #cardHeaderOpenTemplate>
    <div class="bg-white relative z-10 flex justify-between items-center  h-12 w-full">
      <div class="flex items-center">
        <div class="pl-3">
          <p class="text-xs font-medium">{{ 'home.weather.moon.advices.' + moonData.moonPhase | translate }}</p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #cardBodyTemplate>
    <div class="flex flex-col gap-1">
      <h5 class="text-[16px] font-medium text-gray-800">
        {{currentDate | date: 'EEEE dd MMMM' | titlecase}}
      </h5>
      <p class="text-xs text-gray-800">{{moonData.location}}</p>
      <h3 class="text-[32px] font-semibold leading-tight text-[#FFFFFF]">
        {{currentWeather.currentTemperature}}°c
      </h3>
      <div class="pt-3">
        <ul class="flex items-center gap-2">
          <li class="flex items-center gap-[2px]">
            <span>
              <vw-icon-svg iconUrl="/assets/svg/weather/weather-arrow-up.svg" additionalClasses="cursor-pointer"
                width="16" height="16"></vw-icon-svg>
            </span>
            <span class="text-xs text-white">
              {{currentWeather.maxTemperature}}°c</span>
          </li>
          <li class="flex items-center gap-[2px]">
            <span>
              <vw-icon-svg iconUrl="/assets/svg/weather/weather-arrow-down.svg" additionalClasses="cursor-pointer"
                width="16" height="16"></vw-icon-svg>
            </span>
            <span class="text-xs text-white">{{currentWeather.minTemperature}}°c</span>
          </li>
          <li class="flex items-center gap-[2px]">
            <span>
              <vw-icon-svg iconUrl="/assets/svg/weather/weather-droplet.svg" additionalClasses="cursor-pointer"
                width="16" height="16"></vw-icon-svg>
            </span>
            <span class="text-xs text-white">{{currentWeather.totalPrecipitation}}mm</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="">
      <vw-icon-svg [iconUrl]="weatherImg" additionalClasses="cursor-pointer" width="112" height="112"></vw-icon-svg>
    </div>
  </ng-template>

</vw-collapsible-card>
<vw-skeleton-loader *ngIf="isLoading" [width]="'100%'" [height]="'50px'"
  [borderRadius]="'0.75rem'"></vw-skeleton-loader>
