<div [ngClass]="expanded ? cardClasses : ''"
  class="border border-gray-200 rounded-xl shadow-lg cursor-pointer p-0 bg-white overflow-hidden"
  (click)="onCardClick()">
  <!-- Header with optional templates for open and close states -->
  <div [ngClass]="{ 'bg-white relative z-10 shadow-sm': expanded }"
    class="flex justify-between items-center h-12 w-full" (click)="onToggle($event)">
    <div class="flex items-center h-full w-full">
      <ng-template *ngIf="!expanded && cardHeaderCloseTemplate"
        [ngTemplateOutlet]="cardHeaderCloseTemplate"></ng-template>
      <ng-template *ngIf="expanded && cardHeaderOpenTemplate" [ngTemplateOutlet]="cardHeaderOpenTemplate"></ng-template>
    </div>
    <div class="flex items-center justify-center px-4 shrink-0">
      <vw-icon-svg [ngClass]="{ 'rotate-90': expanded }" iconUrl="/assets/svg/right-blue.svg"
        additionalClasses="cursor-pointer" width="8"></vw-icon-svg>
    </div>
  </div>
  <p *ngIf="!cardHeaderCloseTemplate && !cardHeaderOpenTemplate" class="text-base font-medium p-3"
    (click)="onToggle($event)">
    Default Header
  </p>

  <!-- Card Content (Body) -->
  <div *ngIf="expanded" class="flex p-3 justify-between rounded-b-xl" [style.background-color]="backgroundColor">
    <ng-container *ngIf="cardBodyTemplate">
      <ng-template [ngTemplateOutlet]="cardBodyTemplate"></ng-template>
    </ng-container>
    <p *ngIf="!cardBodyTemplate" class="p-3">
      Default Body Content
    </p>
  </div>
</div>