var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { BaseApi } from "../../@common/base-api";
export class WeatherApi extends BaseApi {
  constructor() {
    super('weather');
  }
  getStatus() {
    const _super = Object.create(null, {
      get: {
        get: () => super.get
      }
    });
    return __awaiter(this, void 0, void 0, function* () {
      return _super.get.call(this, `status`);
    });
  }
  getMoonStatus() {
    const _super = Object.create(null, {
      get: {
        get: () => super.get
      }
    });
    return __awaiter(this, void 0, void 0, function* () {
      return _super.get.call(this, `moon/status`);
    });
  }
  getClimate(addressUid) {
    const _super = Object.create(null, {
      get: {
        get: () => super.get
      }
    });
    return __awaiter(this, void 0, void 0, function* () {
      return _super.get.call(this, `koeppen-geiger`);
    });
  }
}