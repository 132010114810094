import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TaskComponent } from '../../@commons/@components/task/task.component';
import { SharedModule } from '../../@commons/ui-components/shared.module';
import { ArticleComponent } from "../../@commons/@components/article/article.component";
import { TaskApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/task/task-api';
import {
  TaskListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/task-list.request';
import { StorageService } from '../../@commons/@services/front/storage.service';
import {
  TaskListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/task-list.response';
import {
  CultivationHierarchyApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-hierarchy/cultivation-hierarchy-api';
import { CalendarHierarchiesComponent } from '../@components/calendar-hierarchies/calendar-hierarchies.component';
import {
  CalendarCultivationHierarchyListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';
import {
  CalendarCultivationHierarchyRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/cultivation/calendar-cultivation-hierarchy-list.request';
import moment from 'moment/moment';
import {
  CultivationPeriodType
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/cultivation-period-type.enum';
import { PlantApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/plant/plant-api';
import {
  CultivationGuideToastComponent
} from '../../garden/@toasts/cultivation-guide-toast/cultivation-guide-toast.component';
import {
  CalendarCultivationListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-list.response';
import {
  CultivationVariant
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import {
  CultivationVariantListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/cultivation-variant-list.response';

@Component({
  selector: 'vw-calendar-harvest', // Component selector for app-agenda-task
  standalone: true, // Indicates the component is standalone
  imports: [CommonModule, SharedModule, TranslateModule, TaskComponent, ArticleComponent, CalendarHierarchiesComponent, CultivationGuideToastComponent], // Modules required by this component
  templateUrl: './calendar-harvest.component.html', // HTML template for the component
  styleUrl: './calendar-harvest.component.scss', // Stylesheet for the component
  providers: [{
    provide: CultivationHierarchyApi,
    useClass: CultivationHierarchyApi
  }, {
    provide: PlantApi,
    useClass: PlantApi
  }]
})
export class CalendarHarvestComponent {


  currentMonthCalendarItems: CalendarCultivationHierarchyListResponse[] = [];
  isLoading: boolean = false;
  isShowingGuide: boolean = false;
  selectedCultivation: CalendarCultivationListResponse;
  selectedVariant: CultivationVariantListResponse;

  constructor(private cultivationHierarchyApi: CultivationHierarchyApi,
    private storageService: StorageService) { }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Subscribes to tasks and advices data from AgendaService.
   */
  async ngOnInit() {
    await this.loadCalendar();
  }

  async loadCalendar() {
    this.isLoading = true;
    const calendarRequest: CalendarCultivationHierarchyRequest = new CalendarCultivationHierarchyRequest();
    calendarRequest.from = moment().startOf('month').format('YYYY-MM-DD');
    calendarRequest.to = moment().endOf('month').format('YYYY-MM-DD');
    calendarRequest.cultivationPeriodTypes = [CultivationPeriodType.HARVEST];
    calendarRequest.addressUid = this.storageService.getItem('address-uid');
    this.currentMonthCalendarItems = await this.cultivationHierarchyApi.calendar(calendarRequest, this.storageService.getCurrentLanguage());
    this.isLoading = false;
  }

  onCultivationSelected($event: CalendarCultivationListResponse) {
    this.selectedCultivation = $event;
    this.selectedVariant = $event.variants[0];
    this.isShowingGuide = true;
  }
}
