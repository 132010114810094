<div class="pb-28 overflow-hidden">
  <div>
    <div class="flex items-center gap-y-4 flex-wrap w-full">
      <vw-seed-item *ngFor="let seed of seeds"
                    (click)="onSelectSeedDetails(seed)"
                    class="w-full"
                    [seed]="seed"></vw-seed-item>
    </div>
  </div>
</div>
