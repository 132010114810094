import { Component, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import moment from 'moment'

@Component({
  selector: 'vw-ai-chatbot-message',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './ai-chatbot-message.component.html',
  styleUrl: './ai-chatbot-message.component.scss'
})
export class AiChatbotMessageComponent {
  @Input() text: string = "Hello World !";
  @Input() color: string = "#30A156";
  @Input() textColor: string = "#FFFFFF";
  @Input() date: number = new Date().getTime();
  @Input() showDate: boolean = false;
  @Input() align: 'left' | 'right' = 'left';
  
  public formatDate(): string {
    return moment(this.date).format('hh:mm A');
  }
}
