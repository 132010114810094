import {Routes} from '@angular/router';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {HomeComponent} from '../../home/home.component';
import {GardenComponent} from '../../garden/garden.component';
import {UserProfileComponent} from '../../user-profile/user-profile.component';
import {AgendaComponent} from '../../agenda/agenda.component';
import {AgendaTaskComponent} from '../../agenda/agenda-task/agenda-task.component';
import {AgendaArchivesComponent} from '../../agenda/agenda-archives/agenda-archives.component';
import {UserSeedsComponent} from '../../garden/user-seeds/user-seeds.component';
import {NavigationGuard} from '../guards/navigation.guard';
import {UserHierarchiesComponent} from '../../garden/user-hierarchies/user-hierarchies.component';
import {CalendarComponent} from '../../calendar/calendar.component';
import {CalendarHarvestComponent} from '../../calendar/calendar-harvest/calendar-harvest.component';
import {CalendarSeedlingComponent} from '../../calendar/calendar-seedling/calendar-seedling.component';

export const mainLayoutRoutes: Routes = [
  {
    path: '', // Default route for main layout
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [NavigationGuard],
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        children: [
          {path: '', redirectTo: 'calendar', pathMatch: 'full'}, // Default child route is 'tasks'
          {path: 'seedling', component: CalendarSeedlingComponent},   // Task tab
          {path: 'harvest', component: CalendarHarvestComponent}, // Archive tab
        ],
      },
      {
        path: 'agenda', // Parent path for agenda
        component: AgendaComponent,
        children: [
          {path: '', redirectTo: 'tasks', pathMatch: 'full'}, // Default child route is 'tasks'
          {path: 'tasks', component: AgendaTaskComponent},   // Task tab
          {path: 'archives', component: AgendaArchivesComponent}, // Archive tab
        ],
        canActivate: [NavigationGuard],
      },
      {
        path: 'garden', component: GardenComponent,
        children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'}, // Default child route is 'tasks'
          {path: 'list', component: UserHierarchiesComponent},   // Task tab
          {path: 'seeds', component: UserSeedsComponent}, // Archive tab
        ],
        canActivate: [NavigationGuard],
      },
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [NavigationGuard]
      },
      {path: '', redirectTo: 'home', pathMatch: 'full'},
    ],
  }
];
