import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppMenuItemComponent } from './app-menu-item/app-menu-item.component';
import { AppMenuCentralComponent } from './app-menu-central/app-menu-central.component';
import { MenuAiActionsToastComponent } from '../../@toasts/menu-ai-actions-toast/menu-ai-actions-toast.component';
import { HomeIconComponent } from '../../../@icons/home-icon.component';
import { TaskIconComponent } from '../../../@icons/task-icon.component';
import { GardenIconComponent } from '../../../@icons/garden-icon.component';
import { AccountIconComponent } from '../../../@icons/account-icon.component';
import { CalendarClockIconComponent } from '../../../@icons/calendar-clock-icon.component';
import { WebViewBridgeService } from '../../@services/webview/webview-bridge.service';
import { WebViewAction } from '../../enums/webview-action';

@Component({
  selector: 'vw-app-menu',
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    TranslateModule,
    AppMenuItemComponent,
    AppMenuCentralComponent,
    MenuAiActionsToastComponent,
    HomeIconComponent,
    TaskIconComponent,
    GardenIconComponent,
    AccountIconComponent,
    CalendarClockIconComponent,
  ],
  templateUrl: './app-menu.component.html',
  styleUrl: './app-menu.component.scss'
})
export class AppMenuComponent {
  @Input() currentScreen: string = 'home';
  @Output() screenChange = new EventEmitter<string>();

  isAIModalOpen: boolean = false;
  inputIsFocus: boolean = false;

  constructor(private router: Router, private webViewBridgeService: WebViewBridgeService) {
    // Écoute les événements de navigation pour mettre à jour `currentScreen`
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAIModalOpen = false;
        this.currentScreen = this.extractScreenName(event.url);

      }
    });
  }

  /**
   * Extrait le nom de l'écran depuis l'URL de navigation.
   * @param url - L'URL complète.
   * @returns Le nom de l'écran.
   */
  private extractScreenName(url: string): string {
    const segments = url.split('/').filter((s) => s.length > 0);
    return segments.length > 0 ? segments[0] : 'home'; // Exemple : '/profile' → 'profile'
  }

  changeScreen(screen: string) {
    this.router.navigate([`/${screen}`]); // Navigation via le Router
    this.isAIModalOpen = false;
  }

  onClickProfile() {
    this.changeScreen('profile');
  }

  openAIPopup() {
    this.webViewBridgeService.postMessageToChannel(WebViewAction.vibration, 'true');
    this.isAIModalOpen = !this.isAIModalOpen;
  }

  onAIModalClosed() {
    this.isAIModalOpen = false;
  }

  onInputFocus() {
    this.inputIsFocus = true;
  }

  onInputBlur() {
    this.inputIsFocus = false;
  }
}
