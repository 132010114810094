import { Component } from '@angular/core';

@Component({
  selector: 'vw-moon-icon',
  standalone: true,
  imports: [],
  template: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0009 10.79C18.8436 12.4922 18.2047 14.1144 17.1591 15.4668C16.1135 16.8192 14.7044 17.8458 13.0966 18.4265C11.4888 19.0073 9.74886 19.1181 8.08038 18.7461C6.4119 18.3741 4.88387 17.5345 3.6751 16.3258C2.46633 15.117 1.62682 13.589 1.25479 11.9205C0.882753 10.252 0.993594 8.51208 1.57434 6.9043C2.15508 5.29651 3.18171 3.88737 4.53409 2.84175C5.88647 1.79614 7.50867 1.15731 9.21088 1C8.21429 2.34827 7.73473 4.00945 7.85941 5.68141C7.98409 7.35338 8.70474 8.92506 9.89028 10.1106C11.0758 11.2961 12.6475 12.0168 14.3195 12.1415C15.9914 12.2662 17.6526 11.7866 19.0009 10.79V10.79Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `,
})
export class ChevronRightIconComponent {

}
