<div class="fixed bottom-0 w-full p-4 z-[999] bottom-bar">
  <div class="flex justify-between p-1 gap-20 shadow-lg rounded-2xl z-[999] bg-white relative">
    <div class="grid grid-cols-2 gap-1 relative z-10 w-1/2">
      <vw-app-menu-item [screenName]="'home'" [currentScreen]="currentScreen"
        [label]="'components.app-menu.home' | translate" (click)="changeScreen('home')" #menuItem>
        <vw-home-icon iconTemplate></vw-home-icon>
      </vw-app-menu-item>

      <vw-app-menu-item [screenName]="'calendar'" [currentScreen]="currentScreen"
        [label]="'components.app-menu.calendar' | translate" (click)="changeScreen('calendar')">
        <vw-calendar-clock-icon iconTemplate></vw-calendar-clock-icon>
      </vw-app-menu-item>
    </div>
    <div
      class="w-[70px] h-[70px] rounded-full -mt-[27px] absolute z-[999] left-0 right-0 m-auto flex justify-center items-center">
      <vw-app-menu-central (openAIPopup)="openAIPopup()" [isMenuOpen]="isAIModalOpen">
      </vw-app-menu-central>
    </div>
    <div class="grid grid-cols-2 gap-1 w-1/2">
      <vw-app-menu-item [screenName]="'garden'" [currentScreen]="currentScreen"
        [label]="'components.app-menu.garden' | translate" (click)="changeScreen('garden')">
        <vw-garden-icon iconTemplate></vw-garden-icon>
      </vw-app-menu-item>

      <vw-app-menu-item [screenName]="'profile'" [currentScreen]="currentScreen"
        [label]="'components.app-menu.profile' | translate" (click)="changeScreen('profile')">
        <vw-account-icon iconTemplate></vw-account-icon>
      </vw-app-menu-item>
    </div>
  </div>
  <div class="relative -z-10" [ngClass]="{'z-[999]': inputIsFocus}">
    <vw-menu-ai-actions-toast [isOpen]="isAIModalOpen" (close)="onAIModalClosed()" (inputFocus)="onInputFocus()"
      (inputBlur)="onInputBlur()">
    </vw-menu-ai-actions-toast>
  </div>
</div>