<vw-header-padding-safe-area></vw-header-padding-safe-area>
<div class="p-4 pt-16">
  <vw-header-home [user]="user"></vw-header-home>
  <div class="pb-6 w-full">
    <h2 class="text-primary-title text-2xl font-semibold pb-3">
      {{ "home.weather.title" | translate }}
    </h2>
    <div class="flex flex-col gap-2">
      <!-- Wait for user to be loaded to be sure that address-uid is set to localstorage -->
      <vw-weather-card *ngIf="userLoaded" (cardClick)="onWeatherCardClicked()">
      </vw-weather-card>
      <vw-skeleton-loader
        *ngIf="!userLoaded"
        [width]="'100%'"
        [height]="'50px'"
        [borderRadius]="'0.75rem'"
      ></vw-skeleton-loader>
      <vw-moon-info-card *ngIf="userLoaded" (cardClick)="onMoonCardClicked()">
      </vw-moon-info-card>
      <vw-skeleton-loader
        *ngIf="!userLoaded"
        [width]="'100%'"
        [height]="'50px'"
        [borderRadius]="'0.75rem'"
      ></vw-skeleton-loader>
    </div>
  </div>
  <div class="pb-6 w-full">
    <!-- Tasks  -->
    <h2 class="text-primary-title text-2xl font-semibold pb-3">
      {{ "home.tasks.title" | translate }}
    </h2>
    <div *ngIf="tasks?.length === 0">{{ "home.tasks.empty" | translate }}</div>
    <div *ngFor="let task of tasks">
      <vw-task [task]="task"></vw-task>
    </div>

    <!-- Articles  -->
    <vw-article
      *ngFor="let article of articles"
      [article]="article"
    ></vw-article>
  </div>

  <div class="pb-2 w-full">
    <h2 class="text-primary-title text-2xl font-semibold pb-3">
      {{ "home.cultivations.title" | translate }}
    </h2>
    <div *ngIf="hierarchies?.length === 0 && !isLoading">
      {{ "home.cultivations.empty" | translate }}
      <div class="mt-3 mx-0 p-0">
        <vw-button
          [label]="'home.cultivations.addPlant'"
          (click)="onAddPlant()"
        >
        </vw-button>
      </div>
    </div>
    <vw-user-hierarchies
      [hierarchies]="hierarchies"
      [isLoading]="isLoading"
    ></vw-user-hierarchies>
  </div>
</div>
