<div *ngIf="!isLoading"
  class="h-[calc(100vh-200px)] pb-16  flex flex-col gap-4 w-[calc(100vw-16px)] overflow-scroll  mx-4 relative rounded-l-lg overscroll-none"
  style="--webkit-overflow-scrolling: touch;">
  <!-- FIRST LINE -->
  <div class="sticky top-0 h-12 w-max z-20 flex bg-primary-light border-b border-grey-100 shadow-lg">
    <!-- TOP LEFT -->
    <div class="w-36 bg-primary-light shrink-0 sticky left-0 z-20 top-0 "></div>
    <!-- MONTHS -->
    <div class="flex transition-transform duration-300">
      <div *ngFor="let month of [1,2,3,4,5,6,7,8,9,10,11,12]"
        class="uppercase w-[calc((100vw-160px)/3)] h-12 text-center text-sm text-grey-700 sticky top-0 shrink-0 flex items-center justify-center ">
        {{('common.months.' + month | translate).slice(0, 3)}}
      </div>
    </div>
  </div>
  <vw-calendar-hierarchy-item *ngFor="let hierarchy of hierarchies" [hierarchy]="hierarchy"
    [dayPercentage]="dayPercentage" (cultivationSelected)="onCultivationSelected($event)"></vw-calendar-hierarchy-item>
</div>