<vw-modal
  class="z-[9999]"
  [isOpen]="isOpen"
  [width]="'94%'"
  [height]="'auto'"
  [closeIcon]="'fas fa-times-circle'"
  [showCloseIcon]="true"
  [customClasses]="'rounded-lg border shadow-xl'"
  (close)="close()"
>
  <div modalHeader>
    <div class="flex items-center justify-between">
      <h2 class="text-[#0C0D0D] text-[20px] font-semibold truncate pr-4">
        {{ hierarchy?.name }}
      </h2>

      <vw-close-btn (close)="close()"></vw-close-btn>
    </div>
  </div>

  <div modalBody>
    <div
      class="w-full relative grid p-6 overflow-auto"
      *ngIf="hierarchy"
      [ngClass]="{
        'grid-cols-1 grid-rows-1': hierarchy.cultivations.length === 1,
        'grid-cols-2 gap-6': hierarchy.cultivations.length > 1
      }"
    >
      <!-- TODO maybe use this even when there is only one variant and make it full width/height -->
      <vw-cultivation-item-viewer
        *ngFor="let cultivation of hierarchy.cultivations"
        (click)="onSelectCultivation(cultivation)"
        [cultivation]="cultivation"
        [showName]="true"
        [displayType]="'TINY'"
        class="w-full max-h-30"
      >
      </vw-cultivation-item-viewer>
    </div>
  </div>
</vw-modal>
