import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../@commons/ui-components/shared.module';
import {Seed} from '../@models/seed.interface';
import {TranslateModule} from '@ngx-translate/core';
import {SeedItemComponent} from '../@components/seed-item/seed-item.component';
import {CultivationApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-api';
import {SeedApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/seed/seed-api';
import {
  SeedListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/location/seed-list.request';
import {StorageService} from '../../@commons/@services/front/storage.service';
import {
  SeedListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/seed-list.response';

@Component({
  selector: 'vw-user-seeds', // Selector for the component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, TranslateModule, SeedItemComponent], // Required modules for the component
  templateUrl: './user-seeds.component.html', // Path to the component's HTML template
  styleUrls: ['./user-seeds.component.scss'], // Corrected from styleUrl to styleUrls
  providers: [{
    provide: CultivationApi,
    useClass: CultivationApi
  }, {
    provide: SeedApi,
    useClass: SeedApi
  }]
})
export class UserSeedsComponent implements OnInit {
  seeds: SeedListResponse[] = []; // Array to hold seed data
  currentSeedPage = 0;

  constructor(private router: Router,
              private storageService: StorageService,
              private seedApi: SeedApi) {} // Injecting router and potager service

  /**
   * Lifecycle hook that is called after the component is initialized.
   * Fetches the list of seeds from the service.
   */
  ngOnInit() {
    /*this.cultivationApi.getSeeds().subscribe((seeds) => {
      this.seeds = seeds; // Assign the fetched seeds to the component property
    });*/
    this.loadSeeds();
  }

  loadSeeds() {
    const listRequest : SeedListRequest = new SeedListRequest();
    this.seedApi.list(listRequest, this.currentSeedPage,
      20, this.storageService.getCurrentLanguage()).then((seeds) => {
        this.seeds.push(...seeds.results); // Assign the fetched seeds to the component property
    });
  }

  /**
   * Navigates to the seed details route when a seed is selected.
   * @param seed - The seed for which details are to be displayed
   */
  onSelectSeedDetails(seed: SeedListResponse) {
    this.router.navigate([`garden/seeds/${seed.uid}`], { state: { seedId: seed.uid } });
    // Navigate to the seed details route and pass the seed ID in the state
  }
}
