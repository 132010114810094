import { Component, Input, OnChanges } from '@angular/core';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { MyCultivationListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-list.response';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { CultivationPictureViewerComponent } from '../cultivation-item/cultivation-picture-viewer/cultivation-picture-viewer.component';
import { CultivationVariantViewerComponent } from '../cultivation-item/cultivation-variant-viewer/cultivation-variant-viewer.component';
import { MyCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/my-cultivation-hierarchy-list.response';
import { CultivationItemViewerComponent } from './cultivation-item-viewer/cultivation-item-viewer.component';
import { CalendarCultivationHierarchyListResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/calendar-cultivation-hierarchy-list.response';

@Component({
  selector: 'vw-hierarchy-item',
  standalone: true,
  imports: [
    SharedModule,
    CultivationPictureViewerComponent,
    TranslateModule,
    NgIf,
    NgForOf,
    CultivationVariantViewerComponent,
    NgClass,
    CultivationItemViewerComponent,
  ],
  templateUrl: './hierarchy-item.component.html',
  styleUrl: './hierarchy-item.component.scss',
})
export class HierarchyItemComponent implements OnChanges {
  @Input() hierarchy: any;
  actionRequired: boolean = false;

  ngOnChanges() {
    //TODO - Implement this method
    if ((this.hierarchy as any).actionRequired) {
      this.actionRequired = true;
    }
  }
}
