<div class="bg-white shadow-lg p-4 rounded-xl w-full">
  <div class="absolute right-8 items-end justify-end ">
    <vw-icon-svg iconUrl="/assets/svg/square-pen.svg" additionalClasses="cursor-pointer" width="24px" height="24px"
      (click)="onEditAddress()"></vw-icon-svg>
  </div>
  <div class="flex items-center gap-2 w-full pb-4">
    <vw-location-home-icon class="text-primary"></vw-location-home-icon>
    <p *ngIf="address.line1">{{address.line1 }}</p>
    <p *ngIf="address.line2">{{address.line2 }}</p>
    <p *ngIf="address.postalCode || address.city">{{address.postalCode || '' + ' ' + address.city || ''}}</p>
  </div>
  <div class="">
    <div class="w-full pb-3">
      <p class="text-[#9BA6A1] text-xs">{{"profile.addresses.climate" | translate}}</p>
      <p class="text-sm text-primary-dark" *ngIf="climateType">{{'profile.addresses.climate-types.' +
        climateType | translate}}</p>
      <p class="text-sm text-primary-dark" *ngIf="!climateType">{{'profile.addresses.climate-types.unknown' |
        translate}}</p>
    </div>
    <div class="w-full pb-3">
      <p class="text-[#9BA6A1] text-xs">{{"profile.addresses.locations" | translate}}</p>
      <p class="text-sm text-primary-dark">{{"profile.addresses.locations-description" | translate: {count:
        locations.length} }} </p>
    </div>
  </div>
  <vw-edit-user-adress-toast [isOpen]="isEditToastOpen" (close)="onCloseEditToast()"
    [address]="address"></vw-edit-user-adress-toast>
</div>
