<div class="w-full">
  <button [ngClass]="{
    'bg-primary-100': currentScreen === screenName,
    'bg-grey-100 opacity-50': disabled
    }" class="px-3 py-1 h-16 w-full rounded-xl flex flex-col gap-0.5 items-center justify-center grow"
    [disabled]="disabled">
    <div
      [ngClass]="{'opacity-100': currentScreen === screenName, 'opacity-50': currentScreen !== screenName, 'text-grey-500': disabled, 'text-primary-600': !disabled}"
      class="h-6 flex items-center justify-center">
      <ng-content select="[iconTemplate]"></ng-content>
    </div>

    <div class="relative flex items-center justify-center">
      <span class="text-[12px] tracking-wide transition-all duration-100 absolute" [ngClass]="
          {
          'font-bold': currentScreen === screenName,
          'opacity-60': currentScreen !== screenName,
          'text-grey-500': disabled,
          'text-primary-600': !disabled
          }">
        {{label | translate}}
      </span>
      <!-- This span is used to keep the same size for the item (activate or not) -->
      <span class="text-[12px] tracking-wide bold opacity-0">
        {{label | translate}}
      </span>
    </div>
  </button>
</div>