<div class="flex flex-col gap-8">
    <vw-header-back-btn (back)="onCloseLocationForm()"></vw-header-back-btn>
    <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-4">
            <h2 class="text-[#062825] text-2xl font-semibold">{{location?.name ||
                'profile.addresses.edit-address.create-location.title' | translate}}</h2>
            <div class="flex flex-col gap-2">
                <label class="text-xs font-normal text-[#9BA6A1]  flex">{{
                    'profile.addresses.edit-address.edit-locations.name.label' | translate }}</label>
                <vw-text-input [(value)]="name" (valueChange)="onNameChange($event)"></vw-text-input>
            </div>
            <div class="flex flex-col gap-2">
                <label class="text-xs font-normal text-[#9BA6A1] flex">{{
                    'profile.addresses.edit-address.edit-locations.type.label' | translate }}</label>
                <vw-dropdown [options]="typeOptions" placeholder="Intérieur" class="w-full" [(ngModel)]="selectedType"
                    (ngModelChange)="onTypeDropdownChange($event)"></vw-dropdown>
            </div>
            <div class="flex flex-col gap-2">
                <label class="text-xs font-normal text-[#9BA6A1] flex">{{
                    'profile.addresses.edit-address.edit-locations.sunchine.label' | translate }}</label>
                <vw-dropdown [options]="sunchineOptions" placeholder="Intérieur" class="w-full"
                    [(ngModel)]="selectedSunchine" (ngModelChange)="onSunchineDropdownChange($event)"></vw-dropdown>
            </div>
        </div>
        <vw-button class="mx-auto" (click)="onSubmitLocationForm()"
            label="{{ 'profile.addresses.edit-address.button' | translate }}" [iconPosition]="'left'"
            [color]="'bg-primary hover:bg-black'" [loading]="isSubmittingLocation" [iconTemplate]="iconTemplate"
            [textColor]="'text-white hover:text-white'" customClass="text-center mx-auto">
            <ng-template #iconTemplate>
                <vw-check-icon></vw-check-icon>
            </ng-template>
        </vw-button>
    </div>
    <div class="w-full h-[1px] bg-gray-100"></div>
    <!-- <vw-user-hierarchies [hierarchies]="hierarchies" [isLoading]="isLoading"></vw-user-hierarchies> -->
</div>