import { Component, EventEmitter, OnChanges, Output } from '@angular/core';
import { SharedModule } from '../../ui-components/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CardBlockTitleComponent } from '../../@components/cards/card-block-title/card-block-title.component';
import { ModalAiActionsButtonsComponent } from './modal-ai-actions-buttons/modal-ai-actions-buttons.component';
import { CloseBtnComponent } from '../../@components/close-btn/close-btn.component';
import { Router } from '@angular/router';
import { BaseToastComponent } from '../../ui-components/toast-popup/base-toast-component';
import { AiChatbotComponent } from '../../@components/ai/ai-chatbot/ai-chatbot.component';
import RoutesConstants from '../../constants/routes.constants';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-menu-ai-actions-toast',
  standalone: true,
  imports: [
    SharedModule,
    TranslateModule,
    CardBlockTitleComponent,
    ModalAiActionsButtonsComponent,
    CloseBtnComponent,
    AiChatbotComponent,
    CommonModule
  ],
  templateUrl: './menu-ai-actions-toast.component.html',
  styleUrl: './menu-ai-actions-toast.component.scss'
})
export class MenuAiActionsToastComponent extends BaseToastComponent implements OnChanges {
  inputIsFocus = false;
  @Output() inputFocus = new EventEmitter<void>();
  @Output() inputBlur = new EventEmitter<void>();

  constructor(private router: Router) {
    super();
  }
  ngOnChanges() {
  }

  onScanBarcode() {
    console.log('Scan barcode');
  }
  onAddPlant() {
    this.router.navigate([RoutesConstants.GARDEN_ADDPLANT]);
  }

  onShowTasks() {
    this.router.navigate([RoutesConstants.AGENDA_TASKS]);
  }

  onInputFocus() {
    this.inputIsFocus = true;
    this.inputFocus.emit();
  }

  onInputBlur() {
    this.inputIsFocus = false;
    this.inputBlur.emit();
  }

  onShowMap() {
    console.log('Show map');
  }
}
