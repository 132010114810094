import { Component } from '@angular/core';

@Component({
  selector: 'vw-task-icon',
  standalone: true,
  imports: [],
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5 7.74692V7.74692C3.65625 6.06862 4.61392 3.56435 6.73463 3.21089L6.83309 3.19448C7.56539 3.07243 8.31155 3.31155 8.83651 3.83651L14.4514 9.45139C15.283 10.283 15.221 11.6491 14.3175 12.402L10.7296 15.392C10.3855 15.6788 10.4488 16.2244 10.8494 16.4247V16.4247C11.5861 16.7931 12.439 16.8537 13.2204 16.5932L14.2473 16.2509C14.6176 16.1275 15 16.4031 15 16.7934C15 17.3213 15 17.8809 15 18.3824C15 19.1258 14.2177 19.6088 13.5528 19.2764L13.2111 19.1056C13.0723 19.0361 12.9192 19 12.7639 19H8.15488C8.05996 19 7.97318 19.0536 7.93073 19.1385V19.1385C7.50845 19.9831 6.33918 20.0748 5.79034 19.3065L2.49925 14.6989C2.21714 14.304 2.28519 13.7587 2.6557 13.4452L8.5 8.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  <path d="M19 21V21C19 19.8954 18.1046 19 17 19V19" stroke="currentColor" stroke-width="2"/>
  <path d="M22 21V20C22 17.7909 20.2091 16 18 16H17" stroke="currentColor" stroke-width="2"/>
    </svg>
  `,
})
export class TaskIconComponent {

}
