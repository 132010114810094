import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { CollapsibleCardComponent } from '../../../@commons/@components/cards/collapsible-card/collapsible-card.component';
import { WeatherStatusResponse } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/weather-status.response';
import { WeatherApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/weather/weather-api';
import { UserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';
import { WeatherType } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/weather-type.enum';
import { SkeletonLoaderComponent } from '../../../@commons/@components/loaders/skeleton-loader/skeleton-loader.component';
import {HexToHsl} from '../../../@commons/pipes/HexToHsl';
@Component({
  selector: 'vw-weather-card',
  standalone: true,
  imports: [CommonModule, SharedModule, CollapsibleCardComponent, SkeletonLoaderComponent, HexToHsl],
  templateUrl: './weather-card.component.html',
  styleUrl: './weather-card.component.scss',
  providers: [
    {
      provide: WeatherApi,
      useClass: WeatherApi,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
})
export class WeatherCardComponent implements OnInit {
  @Input() label: string = ''; // label of the card

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // Event emitter to notify parent component about the state change
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>(); // Event for card click handling

  title: string = ''; // Title of the card
  currentWeather: WeatherStatusResponse = null;
  iconUrl: string = null;
  backgroundColor = '#F5F5F5'; // Background color of the card
  weatherImg: string = '';
  currentDate: Date = new Date();
  isLoading: boolean = true;
  constructor(private weatherApi: WeatherApi) { }

  async ngOnInit() {
    console.log('WeatherCardComponent ngOnInit');
    this.currentWeather = await this.weatherApi.getStatus();
    this.setWeatherAdvice();
    this.setWeatherScreenData();
    this.isLoading = false;
  }

  setWeatherAdvice() {
    let adviceMessage: string;

    if (this.currentWeather) {
      switch (this.currentWeather.currentWeather) {
        case WeatherType.SUN:
          if (this.currentWeather.currentTemperature > 25) {
            adviceMessage =
              "Températures élevées : Paille tes cultures pour maintenir l'humidité.";
          } else if (this.currentWeather.currentTemperature > 20) {
            adviceMessage =
              'Il fait chaud : Ajoute un ombrage aux plantes qui préfèrent le frais.';
          } else {
            adviceMessage =
              "Temps ensoleillé : N'oublie pas de vérifier l'humidité du sol!";
          }
          break;
        case WeatherType.CLOUD:
          adviceMessage =
            "Temps nuageux : C'est un bon moment pour planter des graines ou transplanter!";
          break;
        case WeatherType.RAIN:
          if (this.currentWeather.totalPrecipitation > 20) {
            adviceMessage =
              'Forte pluie : Attention au ruissellement, cela pourrait éroder votre sol!';
          } else if (this.currentWeather.totalPrecipitation > 5) {
            adviceMessage =
              'Pluie modérée : Les plants bien établis devraient être ravis!';
          } else {
            adviceMessage =
              "faibles pluies : Pas besoin d'arroser votre jardin aujourd'hui.";
          }
          break;
        case WeatherType.THUNDER:
          adviceMessage =
            "Orage : N'oublie pas de rentrer tout matériel susceptible de fuir ou d'être endommagé par la pluie.";
          break;
        case WeatherType.WIND:
          adviceMessage =
            "C'est venteux : Assure-toi que les plantations et les supports résistent au vent.";
          break;
        case WeatherType.SNOW:
          adviceMessage =
            'Il neige : Protége les plantes vivaces non rustiques.';
          break;
        case WeatherType.CLEAR:
          adviceMessage =
            "Temps clair : Une bonne opportunité pour faire pousser des graines à l'intérieur.";
          break;
        default:
          adviceMessage = 'Rien à signaler pour le moment.';
          break;
      }
    }
    this.title = adviceMessage;
  }

  setWeatherScreenData() {
    if (this.currentWeather) {
      switch (this.currentWeather.currentWeather) {
        case WeatherType.SUN:
          if (this.currentWeather.currentTemperature > 25) {
            this.iconUrl = '/assets/svg/weather/sun-high-heat-colored.svg';
            this.backgroundColor = '#F49F37';
            this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          } else if (this.currentWeather.currentTemperature > 20) {
            this.iconUrl = '/assets/svg/weather/sun-heat-colored.svg';
            this.backgroundColor = '#F4D537';
            this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          } else {
            this.iconUrl = '/assets/svg/weather/sun-colored.svg';
            this.backgroundColor = '#FCFDDE';
            this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          }
          break;
        case WeatherType.CLOUD:
          this.iconUrl = '/assets/svg/weather/cloud-colored.svg';
          this.backgroundColor = '#ECEBE7';
          this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          break;
        case WeatherType.RAIN:
          if (this.currentWeather.totalPrecipitation > 15) {
            this.iconUrl = '/assets/svg/weather/cloud-rain-white.svg';
            this.backgroundColor = '#205280';
            this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          }
          if (this.currentWeather.totalPrecipitation > 5) {
            this.iconUrl = '/assets/svg/weather/cloud-rain-white.svg';
            this.backgroundColor = '#45739f';
            this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          } else {
            this.iconUrl = '/assets/svg/weather/cloud-rain-colored.svg';
            this.backgroundColor = '#E1EDFE';
            this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          }
          break;
        case WeatherType.THUNDER:
          this.iconUrl = '/assets/svg/weather/cloud-thunder-colored.svg';
          this.backgroundColor = '#C7CCD3';
          this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          break;
        case WeatherType.WIND:
          this.iconUrl = '/assets/svg/weather/cloud-wind-colored.svg';
          this.backgroundColor = '#F6F7F8';
          this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          break;
        case WeatherType.SNOW:
          this.iconUrl = '/assets/svg/weather/snow-colored.svg';
          this.backgroundColor = '#F6F7F8';
          this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          break;
        case WeatherType.CLEAR:
          this.iconUrl = '/assets/svg/weather/cloudy-colored.svg';
          this.backgroundColor = '#F6F7E8';
          this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          break;
        default:
          this.iconUrl = '/assets/svg/weather/cloudy-colored.svg';
          this.backgroundColor = '#F6F7E8';
          this.weatherImg = '/assets/svg/weather/weather-tree.svg'; //TODO change this
          break;
      }
    }
  }

  protected readonly Math = Math;
}
