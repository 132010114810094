<div class="flex items-baseline justify-between pb-3 relative cursor-pointer z-[999]" id="dropdownIcon">
  <vw-engine-icon (click)="showDropdown = !showDropdown"></vw-engine-icon>

  <div [ngClass]="{
    'opacity-100 pointer-events-auto visible translate-y-0': showDropdown,
    'opacity-0 pointer-events-none invisible -translate-y-2': !showDropdown
  }"
    class="absolute right-0 mt-8 w-48 bg-white border invisible rounded shadow-lg py-1 text-red-700 hover:text-white transition-all duration-300"
    id="dropdown">
    <div class="flex justify-center p-2 gap-2" (click)="logout()">
      <vw-logout-icon class="text-red-400"></vw-logout-icon>
      <span class="text-red-400 font-semibold text-base z-[9]">
        {{"profile.logout" | translate}}</span>
    </div>
  </div>
</div>