import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserInfosComponent } from '../../../onboarding/@wizzards/user-infos/user-infos.component';
import { BaseToastComponent } from '../../../@commons/ui-components/toast-popup/base-toast-component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CloseBtnComponent } from '../../../@commons/@components/close-btn/close-btn.component';
import {
  GrowingStepSlideViewerComponent
} from '../../../garden/@components/growing-step-slide-viewer/growing-step-slide-viewer.component';
import { LabelPipe } from '../../../@commons/pipes/LabelPipe';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CustomFormComponent } from '../../../@commons/ui-components/custom-form/custom-form.component';
import { User } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/user.entity';
import { UserApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/user/user-api';
import {
  UpdateUserRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/user/update-user.request';
import { StorageService } from '../../../@commons/@services/front/storage.service';
import { FileApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/file/file-api';
import {
  UploadFileResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/dms/upload-file-response';
import {
  UpdateExternalFileRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/dms/update-external-file-request';

@Component({
  selector: 'vw-edit-user-toast',
  standalone: true,
  imports: [
    UserInfosComponent,
    CarouselModule,
    CloseBtnComponent,
    GrowingStepSlideViewerComponent,
    LabelPipe,
    NgForOf,
    NgIf,
    SharedModule,
    TranslateModule,
    NgSwitchCase,
    NgSwitch,
    FormsModule,
    CustomFormComponent
  ],
  templateUrl: './edit-user-toast.component.html',
  styleUrl: './edit-user-toast.component.scss',
  providers: [
    {
      provide: UserApi,
      useClass: UserApi
    }, {
      provide: FileApi,
      useClass: FileApi
    }
  ]
})
export class EditUserToastComponent extends BaseToastComponent implements OnInit, OnChanges {
  @Input() user: User;
  formData: any = {
  };
  formConfig = {
    title: 'profile.edit-account.title',
    description: 'profile.edit-account.description',
    submitButtonLabel: 'profile.edit-account.save',
    fields: [
      {
        name: 'picture',
        label: 'profile.edit-account.picture.label',
        type: 'img',
        placeholder: 'profile.edit-account.picture.placeholder',
      },
      {
        name: 'pseudo',
        label: 'profile.edit-account.pseudo.label',
        type: 'text',
        placeholder: 'profile.edit-account.pseudo.placeholder',
        required: true,
      }, {
        name: 'expertise',
        label: 'profile.edit-account.expertise.label',
        type: 'dropdown',
        options: 'interior', // This will select interiorOptions array
        placeholder: 'profile.edit-account.expertise.placeholder',
        possibleValues: [
          { value: 'BEGINNER', label: 'profile.edit-account.expertise.options.BEGINNER' },
          { value: 'INTERMEDIATE', label: 'profile.edit-account.expertise.options.INTERMEDIATE' },
          { value: 'EXPERT', label: 'profile.edit-account.expertise.options.EXPERT' },
        ],
        required: true,
      }, {
        name: 'preferredLanguage',
        label: 'profile.edit-account.language.label',
        type: 'dropdown',
        options: 'selection', // This will select selectionOptions array
        possibleValues: [
          { value: 'FR', label: 'common.languages.FR' },
          { value: 'NL', label: 'common.languages.NL' },
          { value: 'EN', label: 'common.languages.EN' },
          { value: 'DE', label: 'common.languages.DE' },
          { value: 'IT', label: 'common.languages.IT' },
          { value: 'ES', label: 'common.languages.ES' },
        ],
        placeholder: 'profile.edit-account.language.label',
        required: true,
      }
    ]
  }
  editUserLoading = false;

  constructor(private userApi: UserApi,
    private fileApi: FileApi,
    private storageService: StorageService,
    private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.loadFormData();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['user']) {
      this.loadFormData();
    }
  }

  loadFormData() {
    if (this.user) {
      this.formData = {
        pseudo: this.user.pseudo,
        registrationDate: this.user.registrationDate,
        expertise: this.getSelectedValue('expertise', this.user.expertise),
        preferredLanguage: this.getSelectedValue('preferredLanguage', this.user.preferredLanguage),
        picture: this.user.files ? this.user.files[this.user.files.length - 1] : null
      }
    }
  }

  getSelectedValue(optionName: string, currentValue: any): any {
    if (this.formConfig.fields.some(field => field.name === optionName)) {
      const possibleValues = this.formConfig.fields.find(field => field.name === optionName)['possibleValues'];
      return possibleValues.find(value => value.value === currentValue);
    }
  }

  async onFormSubmitted($event: any) {
    this.editUserLoading = true;
    const updateUserRequest: UpdateUserRequest = new UpdateUserRequest();
    if (this.formData.picture) {
      const isSameFile = this.user.files && this.user.files.some((file) => file.uid == this.formData.picture.uid);
      const hasAPhoto = this.user.files && this.user.files.length > 0;
      if (!isSameFile || !hasAPhoto) {
        const oldFiles = this.user.files;
        if (oldFiles && oldFiles.length > 0) {
          for (const oldFile of oldFiles) {
            await this.fileApi.updateExternalFile({
              uid: oldFile.uid.toString(),
              userUid: null,
            });
          }
        }
        const patchFileRequest = new UpdateExternalFileRequest();
        patchFileRequest.uid = this.formData.picture.externalFileUid;
        patchFileRequest.userUid = this.user.uid;
        await this.fileApi.updateExternalFile(patchFileRequest);
      }
    }
    updateUserRequest.pseudo = this.formData.pseudo;
    updateUserRequest.expertise = this.formData.expertise.value;
    updateUserRequest.preferredLanguage = this.formData.preferredLanguage.value;
    const res = await this.userApi.update(updateUserRequest);
    this.storageService.setCurrentLang(updateUserRequest.preferredLanguage.toUpperCase());
    this.translateService.use(updateUserRequest.preferredLanguage.toLowerCase());
    this.editUserLoading = false;
    this.onClose();
  }
}
