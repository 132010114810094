import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {SharedModule} from "../../../ui-components/shared.module";

@Component({
  selector: 'vw-collapsible-card',
  standalone: true,
  imports: [
    NgIf,
    SharedModule,
    NgTemplateOutlet,
    NgClass
  ],
  templateUrl: './collapsible-card.component.html',
  styleUrl: './collapsible-card.component.scss'
})
export class CollapsibleCardComponent {

  @Input() cardClasses: string;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // Event emitter to notify parent component about the state change
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>(); // Event for card click handling

  @Input() cardHeaderCloseTemplate: TemplateRef<any> | null;
  @Input() cardHeaderOpenTemplate: TemplateRef<any> | null;
  @Input() cardBodyTemplate: TemplateRef<any> | null;
  @Input() backgroundColor: string;

  expanded: boolean;

  onToggle(event: any) {
    this.expanded = !this.expanded;
    this.toggle.emit(this.expanded); // Emit the current expanded state
  }

  // Handle card click event and propagate it
  onCardClick() {
    this.cardClick.emit();
  }

}
