<div class="flex flex-col items-center w-full h-full " [ngClass]="{
       'min-h-30': displayType === 'SMALL'
     }">
  <!-- Fond + Image -->
  <div class=" rounded-lg w-full flex items-center justify-center h-full aspect-square relative"
    [style]="'background-color: ' + (cultivation.backgroundColor | HexToHsl) + ';'" [ngClass]="{
         '': displayType === 'SMALL',
         '': displayType === 'BIG',
         ' h-32': displayType === 'TINY'
       }">
    <vw-icon-svg [iconUrl]="imageUrl"
      additionalClasses="product-img cursor-pointer mx-auto h-full w-8/12 object-contain">
    </vw-icon-svg>
    <div class="absolute bottom-[10%] h-[13%] w-[60%] rounded-[50%] bg-black opacity-5 blur-sm"></div>
  </div>

  <!-- Texte sous l’image, caché si showName est false -->
  <h3 class="text-sm font-semibold text-[#023E42] mt-2 text-center" *ngIf="showName">
    {{ cultivation.name }}
  </h3>
</div>